import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Descriptions, Drawer } from "antd";
import { ModifiedAntTable } from "./ModifiedAntTable";
import { useQueryParam, NumberParam } from "use-query-params";
import { useParams } from "react-router";
import { useFilterDateDropdown, useListTitle } from "../hooks";
import { reportDeleteAction, getReportsAction } from "../store/org";
import { getIdFieldAndId } from "../utils";

const ReportsList = () => {
  const [loading, setLoading] = useState(false);
  const [reports, setProcurements] = useState([]);
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [filterDate, setFilterDate] = useState();
  const [filteredReports, setFilteredReports] = useState([]);
  const [page, setPage] = useQueryParam("page", NumberParam);
  const [activeReport, setActiveReport] = useState();

  const { orgType, orgId } = useParams();
  const dispatch = useDispatch();
  const { renderFilter } = useFilterDateDropdown({ setFilterDate });
  const {
    users: { user },
  } = useSelector((state) => ({
    users: state.users,
  }));
  const { fullListName } = useListTitle("reports")
  const { org_id_field, org_id } = getIdFieldAndId(orgType, orgId)

  useEffect(() => {
    setFilteredReports(reports);
  }, [reports]);

  useEffect(() => {
    let queryString = `${org_id_field}=${org_id}`;

    if (filterDate) {
      queryString = queryString.concat(
        `&start_date=${filterDate[0].format(
          "YYYY/MM/DD"
        )}&end_date=${filterDate[1].format("YYYY/MM/DD")}`
      );
    }

    setLoading(true);
    dispatch(getReportsAction(queryString))
      .then((result) => {
        setProcurements(result);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }, [filterDate, refreshTrigger]);

  const handleDelete = async (id) => {
    setActiveReport()
    if (
      window.confirm(
        "Are you sure you want to delete this procurment from record?."
      )
    ) {
      await dispatch(reportDeleteAction(id));
      setRefreshTrigger((current) => !current);
    }
  };

  const tableColumn = [
    {
      title: "S/N",
      dataIndex: "id",
      render: (text, record, index) =>
        ((Number(page) || 1) - 1) * 50 + (index + 1),
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Staff (For Staff Report)",
      render: (text, record) => <div>{record?.user?.name}</div>,
    },
    {
      title: "Org (For Org Report)",
      render: (text, record) => <div>{record?.org_name}</div>,
    },
    {
      title: "Reported By",
      dataIndex: "quantity",
      render: (text, record) => <div>{record?.reporter?.name}</div>,
    },
    {
        title: "",
        render: (text, record) => <div className="btn btn-sm btn-primary" onClick={() => setActiveReport(record)}>View detail</div>
      },
  ];

  if (["DR"].includes(user?.position)) {
    tableColumn.push({
      title: "",
      dataIndex: "id",
      render: (text, record) => (
        <span role="button" onClick={(e) => {
            e.stopPropagation()
            handleDelete(record?.id)
        }}>
          <i className="fas fa-trash"></i>
        </span>
      ),
      summary: "-",
    });
  }

  return (
    <div>
      <h3 className="page-title mb-4">{fullListName}</h3>

      <Row compact className="mb-3 filter-panel">
        <Col className="d-flex">
          {renderFilter()}
          <DatePicker.RangePicker
            style={{ width: "100%" }}
            onChange={(val) => setFilterDate(val)}
            className="mb-3"
            value={filterDate}
          />
        </Col>
      </Row>

      <ModifiedAntTable
        loading={loading}
        scroll={{ x: "max-content" }}
        columns={tableColumn}
        dataSource={filteredReports}
        showSummary={true}
        bordered
        pagination={{
          defaultCurrent: page,
          current: page,
          defaultPageSize: 50,
          hideOnSinglePage: true,
          onChange: (current) => setPage(current),
        }}
        onRow={(record, rowIndex) => ({
          onClick: () => setActiveReport(record),
        })}
      />

      {!!activeReport && (
        <Drawer
          title={`Reported by: ${activeReport?.reporter?.name}`}
          placement="bottom"
          onClose={() => setActiveReport(undefined)}
          visible={!!activeReport}
          size="large"
        >
          <Descriptions title={activeReport?.date}>
            <Descriptions.Item label="Report Type">
              {activeReport?.report_type}
            </Descriptions.Item>
            <Descriptions.Item label="Report Type">
              {activeReport?.report_type}
            </Descriptions.Item>

            {activeReport?.report_type === "org" ? (
              <Descriptions.Item label="Org">
                {activeReport?.org_name}
              </Descriptions.Item>
            ) : (
              <Descriptions.Item label="Staff">
                {activeReport?.user?.name}
              </Descriptions.Item>
            )}
          </Descriptions>

          <hr />

          <p>{activeReport?.content}</p>
        </Drawer>
      )}
    </div>
  );
};

export { ReportsList };
