import { parse } from "query-string";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

const useListTitle = (listName, ownList) => {
  const { name } = parse(window.location.search);
  const { pathname } = useLocation();
  const {
  } = useSelector((state) => ({
    users: state.users
  }));

  const isNotSelf = pathname?.includes('branches')

  if (name) {
    if (ownList || !isNotSelf) {
      return {
        fullListName: `List of ${name}'s ${listName}`,
      }
    }
    return {
      fullListName: `List of ${listName} in ${name}`,
    };
  }

  return {
    fullListName: `List of ${listName}`,
  };
};

export { useListTitle };

