import { DatePicker, Input, Select } from "antd";
import { useFilterDateDropdown, useListTitle } from "../hooks";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useQueryParam, NumberParam } from "use-query-params";
import { deleteSpecialIncomeAction, getAllSpecialIncomeAction }from "../store/org";
import { getSummationOfFields, getIdFieldAndId } from "../utils";
import { ModifiedAntTable } from ".";
import { specialIncomeCategories } from "./SpecialIncomeForm";
import { Col, Row } from "react-bootstrap";

const SpecialIncomeList = () => {
  const [filterCategory, setFilterCategory] = useState();
  const [filterDate, setFilterDate] = useState([
    moment().subtract(10, "days"),
    moment(),
  ]);
  const [summedData, setSummedData] = useState({});
  const [loading, setLoading] = useState();
  const [specialIncomes, setSpecialIncomes] = useState([]);
  const [filteredSpecialIncome, setFilteredSpecialIncomes] = useState([]);
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [page, setPage] = useQueryParam("page", NumberParam);
  const dispatch = useDispatch();
  const { orgType, orgId } = useParams();
  const {
    users: { user },
  } = useSelector((state) => ({
    users: state.users,
  }));
  const { org_id_field, org_id } = getIdFieldAndId(orgType, orgId)
  const { renderFilter } = useFilterDateDropdown({ setFilterDate, menuClassName: 'mt-0' });
  const { fullListName } = useListTitle("special incomes")

  useEffect(() => {
    setFilteredSpecialIncomes(specialIncomes)
  }, [specialIncomes])

  useEffect(() => {
    if (!filterCategory) {
        setFilteredSpecialIncomes(specialIncomes)
        return
    }
    setFilteredSpecialIncomes(specialIncomes?.filter(data => data?.category === filterCategory))
  }, [filterCategory])

  useEffect(() => {
    setSummedData(
      getSummationOfFields({
        data: filteredSpecialIncome,
        fieldsToSum: ["amount"],
      })
    );
  }, [filteredSpecialIncome]);

  useEffect(() => {
    const queryString = `start_date=${filterDate[0].format(
      "YYYY/MM/DD"
    )}&end_date=${filterDate[1].format("YYYY/MM/DD")}&${org_id_field}=${org_id}`;

    setLoading(true);
    dispatch(getAllSpecialIncomeAction(queryString))
      .then((result) => {
        setSpecialIncomes(result);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }, [filterDate, refreshTrigger]);

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this withdrawal from record?.")) {
      await dispatch(deleteSpecialIncomeAction(id));
      setRefreshTrigger(current => !current)
    }
  };

  const tableColumn = [
    {
      title: "S/N",
      dataIndex: "id",
      render: (text, record, index) =>
        ((Number(page) || 1) - 1) * 50 + (index + 1),
      summary: "Summation"
    },
    {
      title: "Date",
      dataIndex: "date",
      summary: "-",
    },
    {
        title: "Amount (₦)",
        dataIndex: "amount",
        render:(text, record, index) => <div>{Number(record?.amount).toLocaleString()}</div>,
        summary: summedData['amount']
      },
    {
      title: "Category",
      dataIndex: "category",
      summary: "-"
    },
    {
      title: "Added by",
      render: (text, record, index) => `${record?.added_by?.name} (${record?.added_by?.position})`,
      summary: "-"
    },
    {
      title: "Description",
      render: (text, record) => <div>{record?.description}</div>,
      summary: "-"
    },
  ];

  if (["DR", "SM", "ZM"].includes(user?.position)) {
    tableColumn.push({
      title: "",
      dataIndex: "id",
      render: (text, record) => (
        <span role="button" onClick={() => handleDelete(record?.id)}>
          <i className="fas fa-trash"></i>
        </span>
      ),
      summary: "-",
    });
  }

  return (
    <div>
      <h3 className="page-title mb-4">{fullListName}</h3>
      <Input.Group compact className="mb-3 filter-panel">
        <Select
          allowClear
          className="default-ant-select"
          placeholder="Filter by category"
          value={filterCategory}
          onChange={(val) => setFilterCategory(val)}
        >
          {specialIncomeCategories?.map((val, index) => (
            <Select.Option value={val} key={index}>
              {val}
            </Select.Option>
          ))}
        </Select>
      </Input.Group>

      <Row>
        <Col className="d-flex">
          {renderFilter()}
          <DatePicker.RangePicker
            style={{ width: "100%" }}
            onChange={(val) => setFilterDate(val ?? [])}
            className="mb-3"
            value={filterDate}
          />
        </Col>
      </Row>

      <ModifiedAntTable
        loading={loading}
        scroll={{ x: "max-content" }}
        columns={tableColumn}
        dataSource={filteredSpecialIncome}
        bordered
        showSummary={true}
        pagination={{
          defaultCurrent: page,
          current: page,
          defaultPageSize: 50,
          hideOnSinglePage: true,
          onChange: (current) => setPage(current),
        }}
      />
    </div>
  );
};

export { SpecialIncomeList };
