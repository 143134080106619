import { createSlice } from "@reduxjs/toolkit";
import { POSITIONS_WITH_BRANCHES, getOrgDashboardIdField, makeRequest, BASE_API_URL } from "../utils";
import { showAlertAction } from "./alert";

const slice = createSlice({
  name: "org",
  initialState: {
    cos: [],
    branches: [],
    orgDashboardData: {},
    metaData: {},
    orgClients: [],
    loadingOrgDashboard: false,
    loading: false,
    externalInvestments: [],
    refundToExternalInvestments: [],
  },
  reducers: {
    createBranchSuccess: (state, action) => {
      state.branches.push(action.payload);
    },
    setBranches: (state, action) => {
      state.branches = action.payload;
    },
    setLoadingDashobard: (state, action) => {
      state.loadingOrgDashboard = action.payload;
    },
    setOrgDashboardData: (state, action) => {
      state.orgDashboardData = {
        ...state.orgDashboardData,
        [action.payload.id]: action.payload.orgDashboardData,
      };
    },
    setMetaData: (state, action) => {
      state.metaData = {
        ...state.metaData,
        [action.payload.id]: action.payload.org,
      };
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setExternalInvestments: (state, action) => {
      state.externalInvestments = action.payload;
    },
    setRefundToExternalInvestments: (state, action) => {
      state.refundToExternalInvestments = action.payload;
    },
  },
});

export default slice.reducer;

const {
  createBranchSuccess,
  setLoadingDashobard,
  setOrgDashboardData,
  setMetaData,
  setLoading,
  setBranches,
  setRefundToExternalInvestments,
  setExternalInvestments,
} = slice.actions;

export const createOrgSectorAction =
  ({ type, data, id = "" }) =>
  async (dispatch) => {
    try {
      const requestType = !!id ? "PUT" : "POST";
      const httpOrgData = await makeRequest(
        `${BASE_API_URL}/org/${type}/${id}`,
        requestType,
        data
      );
      data.type === "branches" && dispatch(createBranchSuccess(httpOrgData));

      dispatch(
        showAlertAction({
          message: "Org has been created successfully",
          level: "success",
          isVisible: true,
        })
      );
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const getOrgSectorAction =
  ({ type, id }) =>
  async (dispatch) => {
    try {
      const httpOrgData = await makeRequest(
        `${BASE_API_URL}/org/${type}/${id}`,
        "GET"
      );
      // Get an org based on ID from endpoint
      return httpOrgData;
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const deleteOrgSectorAction =
  ({ type, id }) =>
  async (dispatch) => {
    try {
      await makeRequest(`${BASE_API_URL}/org/${type}/${id}`, "DELETE");

      dispatch(
        showAlertAction({
          message: "Org has been deleted successfully",
          level: "success",
          isVisible: true,
        })
      );
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const editOrgSectionAction =
  ({ type, id }, data) =>
  async (dispatch) => {
    try {
      return {
        message: "Successfully Updated",
        status: "success",
      };
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const getAllBranches = (queryString) => async (dispatch, getState) => {
  // E.G retrieve all states
  try {
    const { user } = getState().users;

    if (POSITIONS_WITH_BRANCHES?.includes(user?.position) && user?.branch) {
      dispatch(setBranches([user?.branch]));
      return
    }

    const httpOrgData = await makeRequest(
      `${BASE_API_URL}/org/branches/?${queryString || ""}`,
      "GET"
    );

    dispatch(setBranches(httpOrgData));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getOrgTableData = (data) => async (dispatch) => {
  try {
    const httpOrgData = await makeRequest(
      `${BASE_API_URL}/org/org_table/`,
      "POST",
      data
    );

    return httpOrgData;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const setOrgDashboardAction =
  ({ orgId, type }) =>
  async (dispatch) => {
    try {
      console.log("CALLING ORG HERE");
      dispatch(setLoadingDashobard(true));

      let httpOrgData = await makeRequest(
        `${BASE_API_URL}/org/${type}/${orgId}`,
        "GET"
      );

      const getManagerDetails = () => {
        return {
          manager: httpOrgData?.manager?.name,
          managerId: httpOrgData?.manager?.id,
        };
      };

      dispatch(
        setMetaData({
          org: { ...httpOrgData, ...getManagerDetails() },
          id: orgId,
        })
      );

      const orgIdField = getOrgDashboardIdField(type);

      // Dashboard Figures
      const figuresRes = await makeRequest(
        `${BASE_API_URL}/org/figures/?org_id_field=${orgIdField}&org_id=${orgId}`,
        "GET"
      );

      const getDashboardData = () => {
        return {
          accumulations: {
            "Total Processing Fees": `₦ ${
              Number(figuresRes?.total_disbursement_fee) || 0
            }`,
            "Total Completed Loans": figuresRes?.completed_loans || 0,
            "Total Number of Clients": figuresRes?.number_of_clients || 0,
          },
          cardsData: figuresRes,
        };
      };

      const httpOrg = getDashboardData();
      await dispatch(
        setOrgDashboardData({
          orgDashboardData: httpOrg,
          id: orgId,
        })
      );
      dispatch(setLoadingDashobard(false));
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const approveRemitance = (data) => async (dispatch) => {
  try {
    const httpOrgData = await makeRequest(
      `${BASE_API_URL}/org/verify_remittance/`,
      "POST",
      data
    );

    return httpOrgData;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const addOrgExpenseAction = (data) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/org/org_expenses/`, "POST", data);

    dispatch(
      showAlertAction({
        message: "Expense has been registered successfully 🎉",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const addSpecialIncome = (data) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/org/special_income/`, "POST", data);

    dispatch(
      showAlertAction({
        message: "Special income has been registered successfully 🎉",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getAllOrgExpensesAction = (queryString) => async (dispatch) => {
  try {
    const httpOrgData = await makeRequest(
      `${BASE_API_URL}/org/org_expenses/?${queryString || ""}`,
      "GET"
    );

    return httpOrgData;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const deleteOrgExpenseAction = (id) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/org/org_expenses/${id}/`, "DELETE");

    dispatch(
      showAlertAction({
        message: "Expense deleted successfully",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getAllSpecialIncomeAction = (queryString) => async (dispatch) => {
  try {
    const httpOrgData = await makeRequest(
      `${BASE_API_URL}/org/special_income/?${queryString || ""}`,
      "GET"
    );

    return httpOrgData;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const deleteSpecialIncomeAction = (id) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/org/special_income/${id}/`, "DELETE");

    dispatch(
      showAlertAction({
        message: "Special income deleted successfully.",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const deleteSiezedAssetsAction = (id) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/org/siezedassets/${id}/`, "DELETE");

    dispatch(
      showAlertAction({
        message: "Seized asset deleted successfully.",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getSiezedAssets = (queryString) => async (dispatch) => {
  try {
    const httpOrgData = await makeRequest(
      `${BASE_API_URL}/org/siezedassets/?${queryString || ""}`,
      "GET"
    );

    return httpOrgData;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getSiezedAsset = (id, type, data) => async (dispatch) => {
  try {
    const httpOrgData = await makeRequest(
      `${BASE_API_URL}/org/siezedassets/${id}/`,
      type,
      data
    );

    return httpOrgData;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const addSiezedAssets = (data) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/org/siezedassets/`, "POST", data);

    dispatch(
      showAlertAction({
        message: "Asset has been added successfully 🎉",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const sellRetrieveAssetAction =
  (type = "sell", data) =>
  async (dispatch) => {
    try {
      await makeRequest(
        `${BASE_API_URL}/org/siezedassets/${
          type === "sell" ? "sell" : "retrieval"
        }/`,
        "POST",
        data
      );

      dispatch(
        showAlertAction({
          message: `Asset ${type} successfully 🎉`,
          level: "success",
          isVisible: true,
        })
      );
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const procurementCreateAction = (data) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/org/procurements/`, "POST", data);

    dispatch(
      showAlertAction({
        message: "Procurement recorded successfully 🎉",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getProcurementsAction = (queryString) => async (dispatch) => {
  try {
    const httpRes = await makeRequest(
      `${BASE_API_URL}/org/procurements/?${queryString}`,
      "GET"
    );

    return httpRes;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const procurementDeleteAction = (id) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/org/procurements/${id}/`, "DELETE");

    dispatch(
      showAlertAction({
        message: "Procurement deleted successfully",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const reportCreateAction = (data) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/org/reports/`, "POST", data);

    dispatch(
      showAlertAction({
        message: "Report added successfully 🎉",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getReportsAction = (queryString) => async (dispatch) => {
  try {
    const httpRes = await makeRequest(
      `${BASE_API_URL}/org/reports/?${queryString}`,
      "GET"
    );

    return httpRes;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const reportDeleteAction = (id) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/org/reports/${id}/`, "DELETE");

    dispatch(
      showAlertAction({
        message: "Procurement deleted successfully",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const addRetrieveExternalInvestment =
  (data, type = "GET") =>
  async (dispatch) => {
    try {
      const res = await makeRequest(
        `${BASE_API_URL}/org/external_investments/`,
        type,
        type !== "GET" ? data : undefined
      );

      if (type === "GET") {
        dispatch(setExternalInvestments(res));
        return
      }

      dispatch(
        showAlertAction({
          message: "External investment recorded successfully.",
          level: "success",
          isVisible: true,
        })
      );

      return res;
    } catch (error) {
      console.log(error)
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const deleteExternalInvestment = (id) => async (dispatch, getState) => {
  try {
    await makeRequest(`${BASE_API_URL}/org/external_investments/${id}`, "DELETE");

    dispatch(
      showAlertAction({
        message: "Deleted successfully.",
        level: "success",
        isVisible: true,
      })
    );

  } catch (error) {
    console.log(error)
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const addRetrieveRefundToExternalInvestment =
  (data, type = "GET", queryString) =>
  async (dispatch) => {
    try {
      const res = await makeRequest(
        `${BASE_API_URL}/org/refund_to_einvestor/${queryString ?? ""}`,
        type,
        type === 'GET' ? undefined : data
      );

      if (type === "GET") {
        dispatch(setRefundToExternalInvestments(res));
        return
      }

      dispatch(
        showAlertAction({
          message: "Payment recorded successfully",
          level: "success",
          isVisible: true,
        })
      );

      return res;
    } catch (error) {
      console.log(error)
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };


  export const deleteRefundToExternalInvestment = (id) => async (dispatch, getState) => {
    try {
      await makeRequest(`${BASE_API_URL}/org/refund_to_einvestor/${id}`, "DELETE");
  
      dispatch(
        showAlertAction({
          message: "Deleted successfully.",
          level: "success",
          isVisible: true,
        })
      );
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };