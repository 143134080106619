import { Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { getAllCreateCollateralsAction, getSlimClientsWithParams } from "../store/clients";

import { ImageUpload } from ".";

const CollateralForm = () => {
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [imageUrl, setImageUrl] = useState("");

  const [form] = Form.useForm();
  const { user } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    const org_type = ["LM", "UM", "BAG", "BHOA"].includes(user.position) ? "branch_id" : user.position === "BDO" ? "handler_id" : ""
    const org_id = ["LM", "UM", "BAG", "BHOA"].includes(user.position) ? user.branch.id : user.position === "BDO" ? user.id : ""
    const queryString = `${org_type}=${org_id}`;
    
    dispatch(getSlimClientsWithParams(queryString)).then((res) => {
      setClients(res);
      setLoading(false);
    });
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    await dispatch(
      getAllCreateCollateralsAction({
        type: "POST",
        data: { ...values, image: imageUrl },
      })
    );
    form.resetFields();
    setLoading(false);
  };

  return (
    <Container fluid>
      <h3 className="page-title mb-4">Add new client collateral</h3>
      <Form form={form} onFinish={handleSubmit}>
        <Row>
          <Col className="" md="6">
            <Form.Item
              label="Select client"
              name="client_id"
              rules={[
                {
                  required: true,
                  message: "Please select a client",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select client"
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                allowClear
              >
                {clients?.map(({ id, surname, firstname }) => (
                  <Select.Option value={id} key={id}>
                    {`${surname} ${firstname}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please provide collateral name/description",
                },
              ]}
            >
              <Input placeholder="Name" className="form-control" />
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              name="amount"
              label="Amount (Worth of collateral)"
              rules={[
                {
                  required: true,
                  message: "Please provide amount",
                },
              ]}
            >
              <Input
                placeholder="Amount"
                type="number"
                className="form-control"
              />
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              name="date"
              label="Date"
              rules={[
                {
                  required: true,
                  message: "Please provide date",
                },
              ]}
            >
              <Input placeholder="Date" type="date" className="form-control" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col>
            <ImageUpload
              uploadDir="collaterals"
              onImageUpload={setImageUrl}
              onDeleteImage={() => setImageUrl(undefined)}
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <Button
              variant="primary"
              className="px-5"
              disabled={loading}
              type="submit"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export { CollateralForm };