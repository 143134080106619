import React from "react";
import * as json2csv from "json2csv";
import { saveAs } from "file-saver";

// Function to download the JSON data as CSV
const downloadAsCsv = (data) => {
  // Convert the JSON data to CSV format
  const csv = json2csv.parse(data);

  // Create a Blob object containing the CSV data
  const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

  // Use the FileSaver library to download the file
  saveAs(csvBlob, "payment_data.csv");
};

const Json2CsvButton = ({ data, text }) => {
  return (
    <button className="btn btn-secondary btn-sm csv-download-btn" onClick={() => downloadAsCsv(data)}>
       <i class="fas fa-download"></i>
    </button>
  );
};

export { Json2CsvButton };
