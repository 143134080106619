import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Select, Form, Input } from "antd";
import { useLocation, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { permissionLevels } from "../utils";
import { getStaffsWithParams } from "../store/clients";
import { createOrgSectorAction } from "../store/org";

export const ORG_TYPE = [
  { label: "Branch", id: "branches", permissionLevel: permissionLevels.AM },
];

export const INCOME_ORG_TYPE = [
  { label: "Branch", id: "branches", permissionLevel: permissionLevels.BM },
];

const CreateOrgLevel = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { type, id } = useParams();
  const [orgData, setOrgData] = useState();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const isEditPage = pathname?.includes?.("edit");
  const {
    users: { user },
    org: { branches },
  } = useSelector((state) => ({
    org: state.org,
    users: state.users,
  }));

  const handleGetEditingData = async () => {
    if (!isEditPage) return;
    setLoading(true);
    const org = branches?.find((data) => data?.id === Number(id));
    setOrgData({ ...org, manager_id: org?.manager?.id });
    Promise.resolve().then(() => form.resetFields());
    setLoading(false);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    await dispatch(
      createOrgSectorAction({
        data: {
          ...values,
        },
        type: "branches",
        id: orgData?.id,
      })
    );
    form.resetFields()
    setLoading(false);
  };

  useEffect(() => {
    handleGetEditingData();
    dispatch(getStaffsWithParams("", "")).then((result) => setUsers(result));
  }, []);

  return (
    <Container fluid>
      <h3 className="page-title mb-4">
        {isEditPage ? "Edit" : "Create"} Branch
      </h3>

      <Form form={form} onFinish={handleSubmit} initialValues={orgData}>
        <Row>
          <Col md="6">
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please provide name",
                },
              ]}
              label="Organization Name"
              labelAlign="left"
              initialValue={orgData?.name}
            >
              <Input className="form-control" type="text" placeholder="Name" />
            </Form.Item>
          </Col>

          <Col md="6">
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please provide email address",
                },
              ]}
              label="Email Address (required))"
            >
              <Input
                className="form-control"
                type="text"
                placeholder="Email Address"
                disabled={isEditPage}
              />
            </Form.Item>
          </Col>

          <Col md="6">
            <Form.Item
              name="phone_number"
              rules={[
                {
                  required: false,
                  message: "Please provide phone number",
                },
              ]}
              label="Phone Number (Optional)"
            >
              <Input
                className="form-control"
                type="number"
                placeholder="Phone Number"
              />
            </Form.Item>
          </Col>

          <Col md="6">
            <Form.Item
              name="address"
              rules={[
                {
                  required: false,
                  message: "Please provide address",
                },
              ]}
              label="Address (Optional)"
            >
              <Input
                className="form-control"
                type="text"
                placeholder="Address"
              />
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              name="manager_id"
              rules={[
                {
                  required: false,
                  message: "Please select a manager",
                },
              ]}
              label="Select a manager (Optional)"
              initialValue={orgData?.manager_id}
            >
              <Select
                showSearch
                placeholder="Select Manager"
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input?.toLowerCase()) >=
                  0
                }
                allowClear
              >
                {users.map(({ name, id }) => (
                  <Select.Option value={id} key={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <Button
              variant="primary"
              className="px-5"
              disabled={loading}
              type="submit"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export { CreateOrgLevel };
