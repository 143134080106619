import { ModifiedAntTable } from ".";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteHoliday, getHolidays } from "../store/loans";
import { useQueryParam, NumberParam } from "use-query-params";

const tableColumn = [
  {
    title: "S/N",
    dataIndex: "id",
    render: (text, record, index) => index + 1,
  },
  {
    title: "Type of Holiday",
    dateIndex: "name",
    render: (text, record, index) => <>{record.name}</>,
  },
  {
    title: "Date",
    dateIndex: "date",
    render: (text, record, index) => <>{record.date}</>,
  },
  {
    title: "Creator",
    dateIndex: "creator",
    render: (text, record, index) => <>{record.creator.name} - {record.creator.position}</>,
  },
  {
    title: "Description",
    dateIndex: "description",
    render: (text, record, index) => <>{record.description}</>,
  },
];

const ViewHolidays = () => {
  const [loading, setLoading] = useState();
  const [holidays, setHolidays] = useState([]);
  const [columns, setColumns] = useState(tableColumn)
  const [page, setPage] = useQueryParam("page", NumberParam);
  const [triggerRefresh, setTriggerRefresh] = useState(false)
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users);

  const handleDeleteHoliday = async (id) => {
    if (window.confirm("Are you sure you want to delete this holiday?. Note: it would not bring back the already shifted daily payments and savings.")) {
      await dispatch(deleteHoliday(id));
      setTriggerRefresh((current) => !current)
    }
  }

  useEffect(() => {
    if (["DR", "SM"].includes(user?.position)) {
      setColumns((current) => [
        ...current,
        {
          title: "",
          dataIndex: "id",
          render: (text, record) => (
            <span role="button" onClick={() => handleDeleteHoliday(record?.id)}>
              <i className="fas fa-trash"></i>
            </span>
          ),
        },
      ]);
    }
  }, [])

  useEffect(() => {
    setLoading(true);
    dispatch(getHolidays())
      .then((result) => {
        setHolidays(result);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }, [triggerRefresh]);

  return (
    <div>
      <h3 className="page-title mb-4">All Holidays</h3>

      <ModifiedAntTable
        loading={loading}
        scroll={{ x: "max-content" }}
        columns={columns}
        dataSource={holidays}
        bordered
        pagination={{
          defaultCurrent: page,
          current: page,
          defaultPageSize: 50,
          hideOnSinglePage: true,
          onChange: (current) => setPage(current),
        }}
      />
    </div>
  );
};

export { ViewHolidays };
