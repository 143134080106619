import { Dropdown, Menu, Space } from "antd";
import classNames from "classnames";
import moment from "moment";
import React, { useCallback } from "react";

const useFilterDateDropdown = ({ setFilterDate, menuClassName }) => {
  const DateFilterMenu = (
    <Menu
      className={classNames("negative-mt-2", menuClassName)}
      items={[
        {
          label: (
            <div
              className="loan-date-item"
              onClick={() =>
                setFilterDate([
                  moment().subtract(0, "days"),
                  moment(),
                ])
              }
            >
              Today
            </div>
          ),
          key: 1,
        },
        {
          label: (
            <div
              className="loan-date-item"
              onClick={() =>
                setFilterDate([
                  moment().startOf("week"),
                  moment().endOf("week"),
                ])
              }
            >
              This week
            </div>
          ),
          key: 2,
        },
        {
          label: (
            <div
              className="loan-date-item"
              onClick={() =>
                setFilterDate([
                  moment().subtract(1, "months").startOf("month"),
                  moment().endOf("month"),
                ])
              }
            >
              1 month
            </div>
          ),
          key: 3,
        },
        {
          label: (
            <div
              className="loan-date-item"
              onClick={() =>
                setFilterDate([
                  moment().subtract(2, "months").startOf("month"),
                  moment().endOf("month"),
                ])
              }
            >
              2 Months
            </div>
          ),
          key: 4,
        },
        {
          label: (
            <div
              className="loan-date-item"
              onClick={() =>
                setFilterDate([
                  moment().subtract(3, "months").startOf("month"),
                  moment().endOf("month"),
                ])
              }
            >
              3 Months
            </div>
          ),
          key: 5,
        },
        {
          label: (
            <div
              className="loan-date-item"
              onClick={() =>
                setFilterDate([
                  moment().subtract(1, "years").startOf("year"),
                  moment().endOf("year"),
                ])
              }
            >
              1 Year
            </div>
          ),
          key: 6,
        },
      ]}
    />
  );

  const RenderFilter = useCallback(() => (
    <Dropdown
      overlay={DateFilterMenu}
      trigger={["click"]}
      className={classNames("mr-2 mt-1")}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          <i class="fa fa-filter"></i>
        </Space>
      </a>
    </Dropdown>
  ));

  return {
    renderFilter: RenderFilter,
  };
};

export { useFilterDateDropdown };
