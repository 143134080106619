import React from "react";

export const LoadingSpinner = ({ loading }) => {
  return loading ? (
    <div className="d-flex w-100 justify-content-center align-items-center my-2">
      <i class="fas fa-spinner fa-spin "></i>{" "}
      <span className="ml-2">Loading...</span>
    </div>
  ) : null;
};
