import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { getErrorMsg, makeRequest, BASE_API_URL } from "../utils";
import { showAlertAction } from "./alert";

const slice = createSlice({
  name: "loans",
  initialState: {
    loading: false,
    filterDate: [moment().subtract(5, "days"), moment()],
    loans: {
      branches: {},
      areas: {},
      zones: {},
      states: {},
    },
    loadingMonthlyPay: false,
    monthlyPay: {},
  },
  reducers: {
    addNewClientSuccess: (state, action) => {
      state.registeredClients.push(action.payload);
    },
    updateFilterDate: (state, action) => {
      state.filterDate = action.payload;
    },
    setLoans: (state, action) => {
      state.loans = {
        ...state.loans,
        [action.payload?.orgType]: {
          ...state.loans?.[action.payload?.orgType],
          [action.payload?.id]: action.payload.data,
        },
      };
    },
    setMonthlyPay: (state, action) => {
      state.monthlyPay = {
        ...state.monthlyPay,
        [action.payload?.orgType]: {
          ...state.monthlyPay?.[action.payload?.orgType],
          [action.payload?.id]: action.payload.data,
        },
      };
    },
    setLoadingMonthlyPay: (state, action) => {
      state.loadingMonthlyPay = action.payload;
    },
  },
});

export default slice.reducer;

const { updateFilterDate, setLoans, setMonthlyPay, setLoadingMonthlyPay } =
  slice.actions;

export const setDateFilterAction = (dateRange) => async (dispatch) => {
  try {
    dispatch(updateFilterDate(dateRange));
  } catch (error) {
    return getErrorMsg(error?.message);
  }
};

export const validateLoanRequest =
  ({ client, guarantor, collateral_id }) =>
  async (dispatch) => {
    try {
      const httpResponse = await makeRequest(
        `${BASE_API_URL}/loans/request/validate?client=${client}&guarantor=${guarantor}&collateral=${collateral_id}`,
        "GET"
      );
      // Get an org based on ID from endpoint
      return httpResponse;
    } catch (error) {
      return getErrorMsg(error?.message);
    }
  };

export const shouldPayLoanWaiverFeeAction = (client_id) => async (dispatch) => {
  try {
    const httpResponse = await makeRequest(
      `${BASE_API_URL}/loans/request/should-pay-waiver/?client_id=${client_id}`,
      "GET"
    );
    // Get an org based on ID from endpoint
    return httpResponse;
  } catch (error) {
    return getErrorMsg(error?.message);
  }
};

export const newLoanRequest = (data) => async (dispatch) => {
  try {
    const httpResponse = await makeRequest(
      `${BASE_API_URL}/loans/create/`,
      "POST",
      data
    );

    dispatch(
      showAlertAction({
        message: "Congratulations 🎉, your loan request has been queued",
        level: "success",
        isVisible: true,
      })
    );
    // Get an org based on ID from endpoint
    return httpResponse;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getPersistedLoansWithParams =
  (orgType, id, queryString) => async (dispatch, getState) => {
    try {
      const res = await makeRequest(
        `${BASE_API_URL}/loans/?${queryString}`,
        "GET"
      );

      dispatch(
        setLoans({
          orgType,
          id,
          data: res,
        })
      );
    } catch (e) {
      dispatch(
        showAlertAction({
          message: "Unable to retrieve loan data please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const getLoansWithParams =
  (queryString) => async (dispatch, getState) => {
    try {
      const res = await makeRequest(
        `${BASE_API_URL}/loans/?${queryString}`,
        "GET"
      );
      return res;
    } catch (e) {
      dispatch(
        showAlertAction({
          message: "Unable to retrieve loan data please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const getMonthlyPayWithParams =
  (queryString, id, orgType) => async (dispatch) => {
    try {
      dispatch(setLoadingMonthlyPay(true));
      const res = await makeRequest(
        `${BASE_API_URL}/loans/monthly_pay/list/?${queryString}`,
        "GET"
      );

      dispatch(
        setMonthlyPay({
          orgType,
          id,
          data: res,
        })
      );
      dispatch(setLoadingMonthlyPay(false));
    } catch (e) {
      dispatch(
        showAlertAction({
          message: "Unable to retrieve loan data please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const getLoan = (id) => async (dispatch, getState) => {
  try {
    const res = await makeRequest(`${BASE_API_URL}/loans/${id}`, "GET");
    return res;
  } catch (e) {
    dispatch(
      showAlertAction({
        message: "Unable to retrieve loan data please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const deleteLoan = (id) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/loans/${id}`, "DELETE");

    dispatch(
      showAlertAction({
        message: "Loan has been deleted successfully",
        level: "success",
        isVisible: true,
      })
    );
  } catch (e) {
    console.log(e);
    dispatch(
      showAlertAction({
        message: "Loan has been deleted successfully",
        level: "success",
        isVisible: true,
      })
    );
  }
};

export const confirmPayment = (data) => async (dispatch, getState) => {
  try {
    await makeRequest(
      `${BASE_API_URL}/loans/monthly_pay/approve/`,
      "POST",
      data
    );

    dispatch(
      showAlertAction({
        message: "Payment confirmed",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const deletePaymentRecord = (id) => async (dispatch, getState) => {
  try {
    await makeRequest(
      `${BASE_API_URL}/loans/monthly_pay/delete/${id}`,
      "DELETE"
    );

    dispatch(
      showAlertAction({
        message: "Payment deleted successfully",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const confirmRolloverPayment = (id, amount ) => async (dispatch, getState) => {
  try {
    await makeRequest(
      `${BASE_API_URL}/loans/monthly_pay/rollover/approve/`,
      "POST",
      { monthlypay_id: id, amount }
    );

    dispatch(
      showAlertAction({
        message: "Payment confirmed",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const waiveUnwaiveRolloverPayment =
  (id, waive = False, amount) =>
  async (dispatch) => {
    try {
      await makeRequest(
        `${BASE_API_URL}/loans/monthly_pay/rollover/waive/`,
        "POST",
        { monthlypay_id: id, waive, amount }
      );

      dispatch(
        showAlertAction({
          message: "Payment confirmed",
          level: "success",
          isVisible: true,
        })
      );
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message,
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const deleteRolloverPayment =
  (id) =>
  async (dispatch) => {
    try {
      await makeRequest(
        `${BASE_API_URL}/loans/monthly_pay/rollover/delete/${id}`,
        "DELETE"
      );

      dispatch(
        showAlertAction({
          message: "Payment deleted successfully",
          level: "success",
          isVisible: true,
        })
      );
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message,
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const confirmBatchPayment =
  ({ ids }) =>
  async (dispatch, getState) => {
    try {
      await makeRequest(
        `${BASE_API_URL}/loans/monthly_pay/approve_batch/`,
        "POST",
        { ids: ids }
      );

      dispatch(
        showAlertAction({
          message: "Payments confirmed",
          level: "success",
          isVisible: true,
        })
      );
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message,
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const confirmBatchSavings =
  ({ ids }) =>
  async (dispatch, getState) => {
    try {
      await makeRequest(
        `${BASE_API_URL}/loans/daily_savings/approve_batch/`,
        "POST",
        { ids: ids }
      );

      dispatch(
        showAlertAction({
          message: "Payments confirmed",
          level: "success",
          isVisible: true,
        })
      );
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message,
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const disbursePayment = (id, paymentData) => async (dispatch) => {
  try {
    await makeRequest(
      `${BASE_API_URL}/loans/disburse/${id}`,
      "POST",
      paymentData
    );

    dispatch(
      showAlertAction({
        message: "Disbursement confirmed",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

/**
 * Assumes that client's last loan is also their active loan.
 * @param {String} clientId
 * @returns
 */
export const getClientLastLoanAction = (clientId) => async (dispatch) => {
  try {
    const httpRes = await makeRequest(
      `${BASE_API_URL}/loans/?client_id=${clientId}`,
      "GET"
    );

    return httpRes?.[0];
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const createHoliday = (data) => async (dispatch, getState) => {
  try {
    await makeRequest(`${BASE_API_URL}/loans/holidays/`, "POST", data);

    dispatch(
      showAlertAction({
        message: "Holiday created successfully",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getHolidays = (data) => async (dispatch, getState) => {
  try {
    const res = await makeRequest(
      `${BASE_API_URL}/loans/holidays/`,
      "GET",
      data
    );

    return res;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const deleteHoliday = (id) => async (dispatch, getState) => {
  try {
    const res = await makeRequest(
      `${BASE_API_URL}/loans/holidays/${id}/`,
      "DELETE"
    );

    return res;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const withdraw = (data) => async (dispatch, getState) => {
  try {
    await makeRequest(`${BASE_API_URL}/accounts/transactions/`, "POST", data);

    await dispatch(
      showAlertAction({
        message: "Withdrawal successful",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getAllWithdrawals = (querySet) => async (dispatch, getState) => {
  try {
    const res = await makeRequest(
      `${BASE_API_URL}/accounts/transactions/?${querySet}`,
      "GET"
    );

    return res;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};
