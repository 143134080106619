import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router";
import { handleLoginAction } from "../store/users";
import { Admin } from "./Admin";
import { LoadingPage, Login } from "../components";

const ProtectedRoutes = () => {
  const { user } = useSelector((state) => state.users);
  const { message, level, isVisible } = useSelector((state) => state.alerts);
  const dispatch = useDispatch();
  const [authenticating, setAuthenticating] = useState(true);

  const getUser = async () => {
    setAuthenticating(true);
    await dispatch(handleLoginAction());
    setAuthenticating(false);
  };

  useEffect(() => {
    getUser();
  }, []);

  if (authenticating) {
    return <LoadingPage />;
  }

  return (
    <>
      <Alert variant={level} show={isVisible} style={{ zIndex: 10 }}>
        <span>{message}</span>
      </Alert>
      <Switch>
        <Route exact path="/login" render={(props) => <Login {...props} />} />
        <Route
          path="/*"
          render={({ history, location }) => {
            if (!user) {
              console.log({ authenticating, user });
              history.push({
                pathname: "/login",
                search: `?returnTo=${location.pathname}`,
              });
              return null;
            }
            return <Admin key={1} />;
          }}
        />
      </Switch>
    </>
  );
};

export { ProtectedRoutes };
