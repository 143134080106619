import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Form, Input, Select } from "antd";
import { Button, Col, Row } from "react-bootstrap";
import moment from "moment";

import { createHoliday } from "../store/loans";

const CreateHolidays = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    users: { user },
  } = useSelector((state) => ({
    users: state.users,
  }));

  const handleFinish = (values) => {
    setLoading(true);
    dispatch(
      createHoliday({
        ...values,
        creator_id: user?.id,
        date: moment(values?.date).format("YYYY-MM-DD"),
      })
    ).then(() => setLoading(false));
  };

  return (
    <div>
      <h3 className="page-title mb-4">Add Holiday</h3>

      <Form form={form} onFinish={handleFinish}>
        <Row>
          <Col>
            <Form.Item
              name="name"
              label="Type of Holiday"
              rules={[
                {
                  required: true,
                  message: "Please provide name",
                },
              ]}
            >
              <Input
                className="form-control"
                type="text"
                placeholder="Type of holiday"
              />
            </Form.Item>

            <Form.Item
              name="date"
              rules={[{ required: true, message: "Please provide date" }]}
              label="Date"
            >
              <DatePicker
                allowClear
                style={{ width: "100%" }}
                className="mb-3"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="description" label="Additional detail (Optional)">
          <Input
            className="form-control"
            type="text"
            placeholder="Description"
          />
        </Form.Item>

        <Row>
          <Col className="mt-3">
            <Button
              variant="primary"
              className="px-5"
              type="submit"
              disabled={loading}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export { CreateHolidays };
