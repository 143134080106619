import { Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { showAlertAction } from "../store/alert";
import { addNewClient, getStaffsWithParams } from "../store/clients";
import { ImageUpload } from ".";
import { IS_PROD } from "../utils";
import { getAllBranches } from "../store/org";

const RegisterClient = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [handlers, setHandlers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const {
    users: { user },
    org: { branches },
  } = useSelector((state) => ({
    org: state.org,
    users: state.users,
  }));

  useEffect(() => {
    setLoading(true);
    const orgType = user?.position === "UM" ? "branch_id" : undefined;
    const orgFieldId = user?.position === "UM" ? user?.branch?.id : undefined;

    let queryString = "";
    queryString = queryString.concat(`${orgType}=${orgFieldId}`);

    if (user?.position === "BDO") {
      setHandlers([user]);
    } else {
      dispatch(getStaffsWithParams(queryString, "")).then((result) => {
        setHandlers(result);
      });
    }

    dispatch(getAllBranches());
    setLoading(false);
  }, []);

  const handleSubmit = async (values) => {
    if (loading) {
      console.log("loading");
      return;
    }
    if (!imageUrl && IS_PROD) {
      dispatch(
        showAlertAction({
          message: "Please upload an image before submitting this form.",
          level: "danger",
          isVisible: true,
        })
      );
      return;
    }

    setLoading(true);
    await dispatch(addNewClient({ ...values, image: imageUrl }));
    form.resetFields();
    setLoading(false);
  };

  return (
    <>
      <Container fluid>
        <h3 className="page-title mb-4">Register Client</h3>
        <Form form={form} onFinish={handleSubmit}>
          <Row>
            <Col className="" md="6">
              <Form.Item
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Please provide title",
                  },
                ]}
                label="Title"
              >
                <Input
                  placeholder="Title"
                  type="text"
                  className="form-control"
                />
              </Form.Item>
            </Col>
            <Col className="" md="6">
              <Form.Item
                name="surname"
                rules={[
                  {
                    required: true,
                    message: "Please provide surname",
                  },
                ]}
                label="Surname"
              >
                <Input
                  placeholder="Surname"
                  type="text"
                  className="form-control"
                />
              </Form.Item>
            </Col>
            <Col className="" md="6">
              <Form.Item
                name="firstname"
                rules={[
                  {
                    required: true,
                    message: "Please provide a first name",
                  },
                ]}
                label="First Name"
              >
                <Input
                  placeholder="First Name"
                  type="text"
                  className="form-control"
                />
              </Form.Item>
            </Col>

            <Col className="" md="6">
              <Form.Item
                name="house_address"
                rules={[
                  {
                    required: true,
                    message: "Please provide house address",
                  },
                ]}
                label="House Address"
              >
                <Input
                  placeholder="House Adress"
                  type="text"
                  className="form-control"
                />
              </Form.Item>
            </Col>
            <Col className="" md="6">
              <Form.Item
                name="office_address"
                rules={[
                  {
                    required: true,
                    message: "Please provide office address",
                  },
                ]}
                label="Office Address"
              >
                <Input
                  placeholder="Office/Business Address"
                  type="text"
                  className="form-control"
                />
              </Form.Item>
            </Col>

            <Col className="" md="6">
              <Form.Item
                name="phone_number1"
                rules={[
                  {
                    required: true,
                    message: "Please provide phone number 1",
                  },
                ]}
                label="Phone Number 1"
              >
                <Input
                  placeholder="Phone Number 1"
                  type="number"
                  className="form-control"
                />
              </Form.Item>
            </Col>
            <Col className="" md="6">
              <Form.Item name="phone_number2" label="Phone Number 2">
                <Input
                  placeholder="Phone Number 2"
                  type="number"
                  className="form-control"
                  label="Phone Number 2"
                />
              </Form.Item>
            </Col>

            <Col className="" md="6">
              <Form.Item
                name="marital_status"
                rules={[
                  {
                    required: true,
                    message: "Please provide marital status",
                  },
                ]}
                label="Marital Status"
              >
                <Select
                  showSearch
                  placeholder="Select marital Status"
                  optionFilterProp="children"
                  className="custom-input"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                >
                  <Select.Option value="single" key="single">
                    Single
                  </Select.Option>
                  <Select.Option value="married" key="married">
                    Married
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col className="" md="6">
              <Form.Item
                name="number_of_children"
                rules={[
                  {
                    required: true,
                    message: "Please provide number of children",
                  },
                ]}
                label="Number of children"
              >
                <Input
                  placeholder="Number of children"
                  type="number"
                  className="form-control"
                />
              </Form.Item>
            </Col>

            <Col className="" md="6">
              <Form.Item
                name="nin"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: "Please provide a valid NIN (11 digits)",
                  },
                  {
                    min: 11,
                    max: 11,
                    message: "Please provide a valid NIN (11 digits)",
                  },
                ]}
                label="NIN number"
              >
                <Input
                  placeholder="Your NIN number"
                  type="number"
                  className="form-control"
                />
              </Form.Item>
            </Col>
            <Col className="" md="6">
              <Form.Item
                name="reg_date"
                rules={[
                  {
                    required: true,
                    message: "Please select registration date",
                  },
                ]}
                label="Date"
              >
                <Input
                  placeholder="Registration Date"
                  type="date"
                  className="form-control"
                />
              </Form.Item>
            </Col>

            <Col className="" md="6">
              <Form.Item
                name="handler_id"
                rules={[
                  {
                    required: true,
                    message: "Please select a handler",
                  },
                ]}
                label="Account handler"
              >
                <Select
                  showSearch
                  placeholder="Select client handler"
                  optionFilterProp="children"
                  className="custom-input"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                >
                  {handlers?.map((inst) => (
                    <Select.Option value={inst.id} key={inst.id}>
                      {inst.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col className="" md="6">
              <Form.Item
                name="branch_id"
                rules={[
                  {
                    required: false,
                    message: "Please select a branch",
                  },
                ]}
                label="Branch"
                help="If you don't select a branch, the handler's branch would be used"
              >
                <Select
                  showSearch
                  placeholder="Select branch"
                  optionFilterProp="children"
                  className="custom-input"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                >
                  {branches?.map((inst) => (
                    <Select.Option value={inst.id} key={inst.id}>
                      {inst.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <ImageUpload
                uploadDir="clients"
                onImageUpload={setImageUrl}
                onDeleteImage={() => setImageUrl(undefined)}
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <Button
                variant="primary"
                className="px-5"
                type="submit"
                disabled={loading}
              >
                {loading ? "Loading..." : "Submit"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export { RegisterClient };
