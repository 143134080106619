import { Form, Input, Select } from "antd";
import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addOrgExpenseAction, getAllBranches } from "../store/org";

export const expenseCategories = [
  "Salary",
  "Transport",
  "Stationaries",
  "Utility",
  "Monitoring team expenses",
  "Printing press",
  "ROI Payments",
  "Others",
];

const OrgExpenseForm = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { user } = useSelector((state) => state.users);
  const {
    org: { branches },
  } = useSelector((state) => ({
    org: state.org,
  }));
  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    setLoading(true);
    await dispatch(addOrgExpenseAction({ ...values, added_by_id: user.id }));
    form.resetFields();
    setLoading(false);
  };

  useEffect(() => {
    let queryString = ''
    if (["LM", "UM", "BAG", "BHOA"].includes(user.position)) {
      queryString = `id=${user?.branch?.id}`
    }
    dispatch(getAllBranches(queryString));
  }, [])

  return (
    <Container fluid>
      <h3 className="page-title mb-4">Add Org Expense</h3>
      <Form form={form} onFinish={handleSubmit}>
        <Row>
          <Col md="6">
            <Form.Item
              name="branch_id"
              rules={[
                {
                  required: true,
                  message: "Please  an option",
                },
              ]}
              label="Branches"
            >
              <Select
                showSearch
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input?.toLowerCase()) >=
                  0
                }
                allowClear
                placeholder="Select a branch"
              >
                {branches?.map(({ id, name }) => (
                  <Select.Option value={id} key={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col md="6">
            <Form.Item
              name="category"
              rules={[
                {
                  required: true,
                  message: "Please an an option",
                },
              ]}
              label="Select category"
            >
              <Select
                showSearch
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input?.toLowerCase()) >=
                  0
                }
                allowClear
              >
                {expenseCategories.map((val, index) => (
                  <Select.Option value={val} key={index}>
                    {val}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item name="description" label="Description">
              <Input
                placeholder="Description"
                type="text"
                className="form-control"
              />
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              name="amount"
              label="Amount"
              rules={[
                {
                  required: true,
                  message: "Please provide amount",
                },
              ]}
            >
              <Input
                placeholder="Amount"
                type="number"
                className="form-control"
              />
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              name="date"
              label="Date"
              rules={[
                {
                  required: true,
                  message: "Please provide date",
                },
              ]}
            >
              <Input placeholder="Date" type="date" className="form-control" />
            </Form.Item>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <Button
              variant="primary"
              className="px-5"
              disabled={loading}
              type="submit"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export { OrgExpenseForm };
