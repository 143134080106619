import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./store";

import { BrowserRouter } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";

import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "antd/dist/antd.css";
import "./assets/scss/index.scss";
import 'react-quill/dist/quill.snow.css';

import { ProtectedRoutes } from "layouts/ProtectedRoutes";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter5Adapter}>
        <ProtectedRoutes />
      </QueryParamProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
