import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { parse } from "query-string";
import { DatePicker, Input, Select } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  useDashboardLoanList,
  useFilterDateDropdown,
  useListTitle,
} from "../hooks";
import classNames from "classnames";
import { deleteLoan } from "../store/loans";
import { useQueryParam, NumberParam } from "use-query-params";
import { ModifiedAntTable } from ".";
import {
  getSummationOfFields,
  getStatusClassName,
  LOANS_STATUS,
  MONTHLY_PAY_STATUS,
} from "../utils";

const DashboardLoanList = ({ showFilter = true }) => {
  const {
    listData,
    handleListRefreshTrigger,
    loading,
    setFilterDate,
    filterDate,
  } = useDashboardLoanList();
  const { user } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const { status: routeStatus } = parse(window.location.search);
  const { fullListName } = useListTitle("loans");

  const [filteredData, setFilteredData] = useState(listData);
  const [filterName, setFilterName] = useState("");
  const [filterStatus, setFilterStatus] = useState(undefined);
  const [filterDailyStatus, setFilterDailyStatus] = useState(undefined);
  const [page, setPage] = useQueryParam("page", NumberParam);
  const { renderFilter } = useFilterDateDropdown({ setFilterDate });

  const [summedData, setSummedData] = useState({});

  useEffect(() => {
    setSummedData(
      getSummationOfFields({
        data: filteredData,
        fieldsToSum: ["loan_amount"],
      })
    );
  }, [filteredData, listData]);

  useEffect(() => {
    if (listData?.length) {
      handleFilter();
    }
  }, [listData]);

  const loanColumns = [
    {
      title: "S/N",
      dataIndex: "id",
      render: (text, record, index) =>
        ((Number(page) || 1) - 1) * 50 + (index + 1),
      summary: ["-"],
    },
    {
      title: "Client",
      dataIndex: "name",
      render: (text, record) => (
        <Link to={`/loans/${record.id}`}>
          <i className="fa fa-link" aria-hidden="true"></i>{" "}
          {`${record?.client_fullname}`}
        </Link>
      ),
      summary: "-",
    },
    {
      title: "Request date",
      dataIndex: "creation_date",
      summary: "-",
    },
    {
      title: "Amount ₦",
      dataIndex: "loan_amount",
      render: (text, record) => <>{Number(text).toLocaleString()}</>,
      summary: summedData["loan_amount"],
    },
    {
      title: "Monthly Payment Status",
      align: "center",
      render: (text, record) => (
        <div className="d-flex justify-content-center">
          <div
            className={classNames(
              "status-badge badge badge-sm",
              record?.current_month_stat === "Paid"
                ? "badge-success"
                : record?.current_month_stat === "Default"
                ? "badge-danger"
                : "badge-warning"
            )}
          >
            {record?.current_month_stat}
          </div>
        </div>
      ),
      summary: "-",
    },
    {
      title: "Loan Status",
      align: "center",
      render: (text, record) => (
        <div className="d-flex justify-content-center">
          <div
            className={classNames(
              "status-badge badge badge-sm",
              getStatusClassName(record?.loan_status)
            )}
          >
            {record?.loan_status}
          </div>
        </div>
      ),
      summary: "-",
    },
  ];

  const [tableColumns, setTableColumns] = useState(loanColumns);

  const handleDeleteLoan = async (id) => {
    if (window.confirm("Are you sure you want to delete this loan?.")) {
      await dispatch(deleteLoan(id));
      handleListRefreshTrigger();
    }
  };

  const handleFilter = useCallback(() => {
    setFilteredData(() => {
      return listData?.filter((val) => {
        const conditions =
          val?.client_fullname
            ?.toLowerCase()
            ?.includes(filterName?.toLowerCase()) &&
          (val?.loan_status === filterStatus ||
            filterStatus === "" ||
            !filterStatus) &&
          (val?.today_payment_stat === filterDailyStatus ||
            filterDailyStatus === "" ||
            !filterDailyStatus);
        return conditions;
      });
    });
  }, [filterName, filterStatus, listData, filterDailyStatus]);

  useEffect(() => {
    // Excludes BDO and LM
    if (user?.permissionLevel > 2) {
      setTableColumns((current) => [
        ...loanColumns,
        {
          title: "",
          dataIndex: "id",
          render: (text, record) => (
            <span role="button" onClick={() => handleDeleteLoan(record?.id)}>
              <i className="fas fa-trash"></i>
            </span>
          ),
          summary: "-",
        },
      ]);
    } else {
      setTableColumns(() => [...loanColumns]);
    }
  }, [summedData]);

  // Handle Filter
  useEffect(() => {
    handleFilter();
  }, [filterName, filterStatus, filterDailyStatus]);

  useEffect(() => {
    setFilterStatus(routeStatus);
  }, [routeStatus]);

  return (
    <Container fluid>
      <h3 className="page-title mb-4">{fullListName}</h3>

      {showFilter && (
        <>
          <Input.Group compact className="mb-3 filter-panel">
            <Select
              allowClear
              className="default-ant-select"
              style={{ width: "30%" }}
              placeholder="Filter by loan status"
              value={filterStatus}
              onChange={(val) => setFilterStatus(val)}
            >
              {LOANS_STATUS.map((val) => (
                <Select.Option value={val} key={val}>
                  {val}
                </Select.Option>
              ))}
            </Select>
            <Select
              allowClear
              className="default-ant-select"
              style={{ width: "40%" }}
              placeholder="Filter by daily payment status"
              value={filterDailyStatus}
              onChange={(val) => setFilterDailyStatus(val)}
            >
              {MONTHLY_PAY_STATUS.map((val) => (
                <Select.Option value={val} key={val}>
                  {val}
                </Select.Option>
              ))}
            </Select>
            <Input
              style={{ width: "30%" }}
              placeholder="Filter by name"
              value={filterName}
              onChange={(e) => setFilterName(e.target.value)}
            />
          </Input.Group>

          <Row>
            <Col className="d-flex">
              {renderFilter()}
              <DatePicker.RangePicker
                style={{ width: "100%" }}
                onChange={setFilterDate}
                className="mb-3"
                value={filterDate}
                allowClear={false}
              />
            </Col>
          </Row>
        </>
      )}

      <Row>
        <Col>
          {loading && (
            <div className="d-flex w-100 justify-content-center align-items-center my-2">
              <i class="fas fa-spinner fa-spin "></i>{" "}
              <span className="ml-2">Loading...</span>
            </div>
          )}
          <ModifiedAntTable
            rowKey="id"
            scroll={{ x: "max-content" }}
            columns={tableColumns}
            dataSource={filteredData}
            bordered
            showSummary={true}
            pagination={{
              defaultCurrent: page,
              current: page,
              defaultPageSize: 50,
              hideOnSinglePage: true,
              onChange: (current) => setPage(current),
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export { DashboardLoanList };
