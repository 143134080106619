import React from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";

import { useDashboard } from "../hooks";
import { PaymentDistributionTable, RenderList } from ".";
import { POSITIONS_WITH_BRANCHES, positionsMapping } from "../utils";

const Dashboard = ({ permissionLevel }) => {
  const history = useHistory();
  const { id } = useParams();
  const {
    metaData,
    isOrgDashboard,
    orgType,
    isAllOrgData,
    user,
    cardsData,
    tableData,
    onDateRangeChange,
    position,
    loadingTableData,
    todayCollection,
    isStaffDashboard,
  } = useDashboard({
    permissionLevel,
    id: id,
  });
  const { pathname } = useLocation();
  const isSelfStaff = user.position === "BDO"; // Someone is logged in as BDO
  const isSelfBranch = isOrgDashboard && pathname === "/"; // Someone is login as one of the users witgh branch access and they are accessing the branch dashboard

  const getListLink = (path, params = "") => {
    const defaultParam = !isAllOrgData ? `?name=${metaData?.name}&` : '?';
    if (isSelfStaff) {
      return `/staffs/${metaData?.id}/${path}${defaultParam}${params}`;
    }
    if (isSelfBranch) {
      return `branches/${metaData?.id}/${path}?${defaultParam}${params}`;
    }
    return `${pathname === "/" ? "" : pathname}/${path}${defaultParam}${params}`;
  };

  const getTitle = () => {
    if (
      isAllOrgData &&
      ["LM", "UM", "BAG", "BHOA"].includes(user.position) &&
      !isStaffDashboard
    ) {
      return `Welcome to ${metaData?.name}`;
    }
    if (!POSITIONS_WITH_BRANCHES.includes(user.position) && isAllOrgData) {
      return `All organization dashboard`;
    }
    return `Welcome to ${metaData?.name || ""}`;
  };

  const cards = [
    {
      title: "Completed Loans",
      subtitle: cardsData?.completed_loans ?? 0,
      iconClassName: "nc-icon nc-chart text-warning",
      onClick: () => {
        history.push(getListLink("loans", "status=COMPLETED"));
      },
    },
    {
      title: "Defaulted amount",
      subtitle: `${
        cardsData?.accumulated_pay_cash_in_hand
          ? `₦ ${Number(
              cardsData?.accumulated_pay_cash_in_hand
            ).toLocaleString()}`
          : "0"
      }`,
      iconClassName: "nc-icon nc-chart text-warning",
      onClick: () => {
        history.push(getListLink("loans", "status=DEFAULT"));
      },
    },
    {
      title: "Total clients",
      subtitle: `${
        cardsData?.number_of_clients
          ? Number(cardsData?.number_of_clients).toLocaleString()
          : "0"
      }`,
      iconClassName: "nc-icon nc-chart text-warning",
      onClick: () => {
        history.push(getListLink("clients"));
      },
    },
    {
      title: "Active clients",
      subtitle: `${
        cardsData?.number_of_active_clients
          ? Number(cardsData?.number_of_active_clients).toLocaleString()
          : "0"
      }`,
      iconClassName: "nc-icon nc-chart text-warning",
      onClick: () => {
        history.push(getListLink("clients", "status=ACTIVE"));
      },
    },
  ];

  const RenderDahboardCards = ({
    title,
    subtitle,
    footerText,
    iconClassName,
    extra,
    onClick,
  }) => {
    return (
      <Col lg="3" sm="6">
        <Card className="card-stats" onClick={onClick}>
          <Card.Body>
            <Row>


                  <i class="fas fa-expand card-icon"></i>

              <Col>
                <div className="numbers">
                  <p className="card-category">{title}</p>
                  <Card.Title as="h4">{subtitle}</Card.Title>
                  <p className="card-category mb-0">{extra}</p>
                </div>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer>
            <hr />
            <div className="stats">{footerText}</div>
          </Card.Footer>
        </Card>
      </Col>
    );
  };

  return (
    <>
      <Container fluid>
        <Row className="col-12 mb-4 greetings mr-0 pr-0 w-100 justify-content-between">
          <div className="p-0" md="6">
            {getTitle()}
          </div>

          <div
            md="6"
            className="d-flex flex-row justify-content-end text-muted p-0"
          >
            {(!isAllOrgData || user?.position === "BDO") && (
              <span className="badge badge-pill color-white mr-2 align-items-center ft-12 position-pill">
                {!isOrgDashboard
                  ? positionsMapping[metaData?.position]
                  : `${orgType}`.toLocaleUpperCase()}
              </span>
            )}

            {!["BDO"].includes(user?.position) && isAllOrgData && (
              <Link to={`/staffs/${user?.id}`}>
                <span className="badge badge-pill color-white align-items-center ft-12">
                  View your dashboard
                </span>
              </Link>
            )}
          </div>
        </Row>
        <Row>
          {cards.map((props, index) => (
            <RenderDahboardCards key={index} {...props} />
          ))}
        </Row>

        <Row>
          <Col md="6">
            <RenderList
              exclude={[
                "id",
                "ManagerId",
                "permissionLevel",
                "branch_id",
                "auth_text",
                "last_notifications_view_time",
              ]}
              data={metaData}
            />
          </Col>
          <Col md="6">
            <RenderList
              extraContent={
                <div>
                  <Link
                    to={getListLink("monthly_pay", "status=unpaid_rollover")}
                  >
                    <strong>
                      Accumulated Rollover:{" "}
                      {cardsData?.accumulated_rollover
                        ? `₦ ${Number(
                            cardsData?.accumulated_rollover
                          ).toLocaleString()}`
                        : "---"}
                    </strong>
                  </Link>
                  <br />

                  {!POSITIONS_WITH_BRANCHES?.includes(user.position) && !id && (
                    <>
                      <Link to="/branches" className="text-info pl-0">
                        View Branches{" "}
                        <i className="fas fa-angle-double-right"></i>
                      </Link>
                      <br />
                    </>
                  )}

                  <Link
                    to={getListLink("monthly_pay")}
                    className="text-info pl-0"
                  >
                    View Monthly Pay
                    <i className="fas fa-angle-double-right"></i>
                  </Link>
                  <br />

                  {metaData?.position !== "BDO" && !isStaffDashboard && (
                    <>
                      <Link to={getListLink("staffs")} className="text-info">
                        View staffs
                        <i className="fas fa-angle-double-right"></i>
                      </Link>
                      <br />
                    </>
                  )}

                  <Link to={getListLink("collaterals")} className="text-info">
                    View collaterals
                    <i className="fas fa-angle-double-right"></i>
                  </Link>
                  <br />

                  <Link to={getListLink("seizedassets")} className="text-info">
                    View Siezed Assets
                    <i className="fas fa-angle-double-right"></i>
                  </Link>

                  <br />

                  {(isAllOrgData || isOrgDashboard) &&
                    user?.position !== "BDO" && (
                      <>
                        <Link
                          to={getListLink("org_expenses")}
                          className="text-info"
                        >
                          View Org Expenses
                          <i className="fas fa-angle-double-right"></i>
                        </Link>
                        <br />

                        <Link
                          to={getListLink("specialincome")}
                          className="text-info"
                        >
                          View Special Incomes
                          <i className="fas fa-angle-double-right"></i>
                        </Link>
                        <br />

                        <Link
                          to={getListLink("procurements")}
                          className="text-info"
                        >
                          View Procurements
                          <i className="fas fa-angle-double-right"></i>
                        </Link>

                        <br />
                      </>
                    )}

                  {(isAllOrgData || isOrgDashboard) && (
                    <>
                      <Link to={getListLink("reports")} className="text-info">
                        View Reports
                        <i className="fas fa-angle-double-right"></i>
                      </Link>
                    </>
                  )}

                  {!(
                    isOrgDashboard ||
                    isStaffDashboard ||
                    POSITIONS_WITH_BRANCHES?.includes(user?.position)
                  ) && (
                    <>
                      <br />
                      <Link
                        to={getListLink("external_investments")}
                        className="text-info"
                      >
                        View External Investments
                        <i className="fas fa-angle-double-right"></i>
                      </Link>

                      <br />
                      <Link
                        to={getListLink("refund_external_investments")}
                        className="text-info"
                      >
                        View Refunds to External Investors
                        <i className="fas fa-angle-double-right"></i>
                      </Link>
                    </>
                  )}
                </div>
              }
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <h6>
              Today's Collections: ₦
              {todayCollection === "-"
                ? " Not available"
                : Number(todayCollection).toLocaleString()}
            </h6>
            <PaymentDistributionTable
              dataset={tableData}
              onDateRangeChange={onDateRangeChange}
              showApproval={pathname === "/" && !(position === "DR")}
              loadingTableData={loadingTableData}
              manageriD={metaData?.managerId}
              figures={cardsData}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export { Dashboard };
