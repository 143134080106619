import React, { useEffect, useState } from "react";
import { Collapse } from "antd";
import { sum } from "lodash";

const { Panel } = Collapse;

const LoanProposeRepaymentPlan = ({
  amount,
  interestRate,
  duration,
  interestType,
  onChangeNetPay,
}) => {
  const [monthlyRepayment, setMonthlyRepyment] = useState([]);
  const monthlyCapital = Number(amount) / Number(duration);

  const getMonthlyRepaymentPlans = () => {
    if (!interestType || !interestRate || !duration || !amount) {
      return;
    }

    let repayments = [];

    if (interestType === "flat") {
      const interestAmount = Number(amount) * (Number(interestRate) / 100);

      for (let s = 0; s < Number(duration); s++) {
        repayments.push(monthlyCapital + interestAmount);
      }
    } else {
      let remainingCapital = Number(amount);

      for (let s = 0; s < Number(duration); s++) {
        const interestAmount =
          Number(remainingCapital) * (Number(interestRate) / 100);
        repayments.push(monthlyCapital + interestAmount);

        remainingCapital = remainingCapital - monthlyCapital;
      }
    }

    setMonthlyRepyment(repayments);
  };

  useEffect(() => {
    getMonthlyRepaymentPlans();
  }, [amount, interestRate, duration, interestType]);

  useEffect(() => {
    onChangeNetPay?.(sum(monthlyRepayment));
  }, [monthlyRepayment]);

  return (
    <>
      <hr />
      <Collapse bordered={false} className="repayment_collapse_wrapper">
        <Panel key="1" header="View monthly repayment outline">
          {monthlyRepayment?.map((instance, index) => (
            <div className="repayment-item d-flex mb-2">
              <div className="mr-3 purple">Month-{index + 1}:</div> ₦{" "}
              {instance?.toLocaleString()}
            </div>
          ))}
        </Panel>
      </Collapse>
    </>
  );
};

export { LoanProposeRepaymentPlan };
