import { createSlice } from "@reduxjs/toolkit";
import { makeRequest, hashToken, BASE_API_URL } from "../utils";
import { showAlertAction } from "./alert";

const slice = createSlice({
  name: "users",
  initialState: {
    token: "",
    user: null,
    users: [],
    userDashboardData: {},
    metaData: {},
    loadingDashboard: false,
    loading: true, //loading login
  },
  reducers: {
    addNewUser: (state, action) => {
      state.users.push(action.payload);
    },
    logout: (state, action) => {
      state.user = null;
      localStorage.removeItem("user_local_token");
    },
    login: (state, action) => {
      state.user = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setDashboardData: (state, action) => {
      state.userDashboardData = {
        ...state.userDashboardData,
        [action.payload.id]: action.payload.userDashboardData,
      };
    },
    setLoadingDashobard: (state, action) => {
      state.loadingDashboard = action.payload;
    },
    setAllUsersSuccess: (state, action) => {
      state.users = action.payload;
    },
    setMetaData: (state, action) => {
      state.metaData = {
        ...state.metaData,
        [action.payload.id]: action.payload.user,
      };
    },
  },
});

export default slice.reducer;

const {
  addNewUser,
  logout,
  login,
  setDashboardData,
  setLoadingDashobard,
  setAllUsersSuccess,
  setMetaData,
  setLoading,
} = slice.actions;

export const handleLoginAction = (authData) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const auth_token = localStorage.getItem("user_local_token");
    const TOKEN_UNAVAILABLE =
      auth_token === "undefined" ||
      auth_token === undefined ||
      auth_token === null;

    const getUserData = async () => {
      const userDetail = await makeRequest(
        `${BASE_API_URL}/auth/users/me/`,
        "GET"
      );
      if (userDetail) {
        await dispatch(login(userDetail));
      }
    };

    const setToken = async () => {
      const res = await makeRequest(
        `${BASE_API_URL}/auth/token/login`,
        "POST",
        authData
      );

      if (res instanceof Error) {
        throw res;
      }

      const token_res = res?.auth_token;
      const newToken = hashToken(token_res);
      localStorage.setItem("user_local_token", newToken);
    };

    if (TOKEN_UNAVAILABLE && authData) {
      await setToken()
        .then(async () => {
          await getUserData();
        })
        .catch((error) => {
          throw error;
        });
    }

    if (!TOKEN_UNAVAILABLE) {
      await getUserData();
    }
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
    return false;
  }
};

export const handleLogoutAction = () => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/auth/token/logout`, "POST");
    dispatch(logout());
    window.location.reload();
  } catch (error) {
    dispatch(logout());
  }
};

export const createNewUser = (userData) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/auth/users/`, "POST", userData);
    dispatch(addNewUser(userData));

    dispatch(
      showAlertAction({
        message: "New staff has been added successfully 🎉",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const updateUser = (id, userData) => async (dispatch) => {
  try {
    await makeRequest(
      `${BASE_API_URL}/accounts/users/update/${id}/`,
      "PUT",
      userData
    );

    dispatch(
      showAlertAction({
        message: "Information updated successfully 🎉",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getUserAction = (userId) => async (dispatch, getState) => {
  try {
    const res = await makeRequest(
      `${BASE_API_URL}/accounts/users/${userId}/`,
      "GET"
    );

    return res;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getAllUserAction = () => async (dispatch, getState) => {
  try {
    const res = await makeRequest(`${BASE_API_URL}/accounts/users/`, "GET");

    dispatch(setAllUsersSuccess(res));
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const setUserDashboard =
  ({ userId, isStaffDashboard }) =>
  async (dispatch, getState) => {
    try {
      const { user, metaData } = getState().users;

      if (!metaData?.[userId]) {
        if (isStaffDashboard) {
          dispatch(getUserAction(userId)).then((data) => {
            dispatch(setMetaData({ id: userId, user: data }));
          });
        } else {
          dispatch(setMetaData({ user, id: userId }));
        }
      }

      dispatch(setLoadingDashobard(true));

      const figuresRes = !isStaffDashboard
        ? await makeRequest(`${BASE_API_URL}/org/figures/`, "GET")
        : await makeRequest(
            `${BASE_API_URL}/org/figures/?org_id_field=handler_id&org_id=${userId}`,
            "GET"
          );

      const getDashboardData = (id) => {
        return {
          cardsData: figuresRes,
        };
      };

      const httpUser = getDashboardData(userId);
      await dispatch(
        setDashboardData({
          userDashboardData: httpUser,
          id: userId,
        })
      );

      dispatch(setLoadingDashobard(false));
      return true;
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const getCos = () => async (dispatch, getState) => {
  try {
    const { user } = getState().users;

    const cos = await makeRequest(
      `${BASE_API_URL}/accounts/users/?position=CO`
    );

    return cos;
  } catch (e) {
    return [];
  }
};

export const deleteUserAction = (id) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/accounts/users/${id}/`, "DELETE");

    dispatch(
      showAlertAction({
        message: "Staff deleted successfully",
        level: "success",
        isVisible: true,
      })
    );
  } catch (e) {
    dispatch(
      showAlertAction({
        message: e?.message || "A erorr occured, please try again.",
        level: "success",
        isVisible: true,
      })
    );
  }
};

export const changeMyPassword = (data) => async (dispatch, getState) => {
  try {
    const res = await makeRequest(
      `${BASE_API_URL}/auth/users/set_password/`,
      "POST",
      data
    );

    dispatch(
      showAlertAction({
        message: "Password change successful",
        level: "success",
        isVisible: true,
      })
    );
    return res;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const staffSalaryAction =
  (data, pk, type = "POST") =>
  async (dispatch, getState) => {
    try {
      const res = await makeRequest(
        `${BASE_API_URL}/accounts/salaries/${pk || ""}`,
        type,
        data
      );

      dispatch(
        showAlertAction({
          message: "Successul",
          level: "success",
          isVisible: true,
        })
      );
      return res;
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const staffEmploymentStatusAction =
  (data, pk, type = "POST") =>
  async (dispatch, getState) => {
    try {
      const res = await makeRequest(
        `${BASE_API_URL}/accounts/employment_status/${pk || ""}`,
        type,
        data
      );

      dispatch(
        showAlertAction({
          message: "Successul",
          level: "success",
          isVisible: true,
        })
      );
      return res;
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const staffAwardsAction =
  (data, pk, type = "POST") =>
  async (dispatch, getState) => {
    try {
      const res = await makeRequest(
        `${BASE_API_URL}/accounts/awards/${pk || ""}`,
        type,
        data
      );

      dispatch(
        showAlertAction({
          message: "Successul",
          level: "success",
          isVisible: true,
        })
      );
      return res;
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const staffQueriesAction =
  (data, pk, type = "POST") =>
  async (dispatch, getState) => {
    try {
      const res = await makeRequest(
        `${BASE_API_URL}/accounts/queries/${pk || ""}`,
        type,
        data
      );

      dispatch(
        showAlertAction({
          message: "Successul",
          level: "success",
          isVisible: true,
        })
      );
      return res;
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const staffTransfersAction =
  (data, pk, type = "POST") =>
  async (dispatch, getState) => {
    try {
      console.log({ pk });
      const res = await makeRequest(
        `${BASE_API_URL}/accounts/transfers/${pk ?? ""}`,
        type,
        data
      );

      dispatch(
        showAlertAction({
          message: "Successul",
          level: "success",
          isVisible: true,
        })
      );
      return res;
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const staffPromotionAction =
  (data, type = "POST") =>
  async (dispatch, getState) => {
    try {
      const res = await makeRequest(
        `${BASE_API_URL}/accounts/promotions/`,
        type,
        data
      );

      dispatch(
        showAlertAction({
          message: "Successul",
          level: "success",
          isVisible: true,
        })
      );
      return res;
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const raisePermissionError = () => async (dispatch, getState) => {
  dispatch(
    showAlertAction({
      message:
        "Sorry! you do not have the necessary permission for this oparation.",
      level: "danger",
      isVisible: true,
    })
  );
};
