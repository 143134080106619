import { createSlice } from "@reduxjs/toolkit";
import { makeRequest, BASE_API_URL } from "../utils";
import { showAlertAction } from "./alert";

const slice = createSlice({
  name: "lists",
  initialState: {
    states: null,
    zones: null,
    areas: null,
    branches: null,
    cos: null,
    clients: null,
    loading: false,
  },
  reducers: {
    getClientsSuccess: (state, action) => {
      state.clients = action.payload;
    },
    getCosSuccess: (state, action) => {
      state.cos = action.payload;
    },
    getBranchesSuccess: (state, action) => {
      state.branches = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export default slice.reducer;

const isPermittedOrError = (permissionLevel, permissionThreshold) => {
  if (permissionLevel < permissionThreshold) {
    throw new Error("You do not have enough permission to view this resourse");
  }
};

const {
  getCosSuccess,
  getClientsSuccess,
  setLoading,
} = slice.actions;

export const getAllClientsAction =
  ({ coId, permissionLevel }) =>
  async (dispatch) => {
    // Get All clients with this CO as their registerer
    try {
      isPermittedOrError(permissionLevel, 1);
      dispatch(setLoading(true));
      const res = await makeRequest(
        `${BASE_API_URL}/accounts/clients/?co_id=${coId}`,
        "GET"
      );

      dispatch(getClientsSuccess(res));
      dispatch(setLoading(false));
      return true;
    } catch (error) {
      dispatch(setLoading(false))
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const getAllCoInBranchAction =
  ({ branchId, permissionLevel }) =>
  async (dispatch) => {
    // Get All clients with this CO as their registerer
    try {
      isPermittedOrError(permissionLevel, 2);
      dispatch(setLoading(true));
      const res = await makeRequest(
        `${BASE_API_URL}/accounts/users/?branch=${branchId}&position=CO`,
        "GET"
      );

      dispatch(getCosSuccess(res));
      dispatch(setLoading(false));
      return true;
    } catch (error) {
      dispatch(setLoading(false))
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const assignListClientsToCoAction =
  (data) => async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const res = await makeRequest(
        `${BASE_API_URL}/accounts/clients/reassign/`,
        "POST",
        data
      );

      await dispatch(
        showAlertAction({
          message: "Clients has been sucessfully re-assigned!",
          level: "success",
          isVisible: true,
        })
      )
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
      dispatch(setLoading(false))
    }
  };
