import React, { useState } from "react";
import { Collapse, Input, Modal } from "antd";
import { sum } from "lodash";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { isOneMonthApart } from "../utils";
import { isSameMonthAndLater } from "../utils";
import { raisePermissionError } from "../store/users";
import {
  confirmPayment,
  confirmRolloverPayment,
  deletePaymentRecord,
  deleteRolloverPayment,
  waiveUnwaiveRolloverPayment,
} from "../store/loans";

const { Panel } = Collapse;

const LoanRepaymentPlan = ({ loan, onTriggerRefresh }) => {
  const {
    users: { user },
  } = useSelector((state) => ({
    users: state.users,
  }));
  const dispatch = useDispatch();
  const [partialAmount, setPartialAmount] = useState();
  const [activeItemId, setActiveItemId] = useState();
  const [loading, setLoading] = useState(false);
  const [activePartialPay, setActivePartialPay] = useState(undefined);
  const shouldAlloWActions = ["DR", "HOA", "BHOA"].includes(user?.position);

  const getPayMetadata = (monthpay) => {
    const { payment_records } = monthpay;
    const totalPaid = sum(
      payment_records?.map((data) => parseInt(data?.amount))
    );
    const totalRemaining = parseInt(monthpay?.amount) - parseInt(totalPaid);
    const installMentWithoutInterest =
      parseInt(loan?.loan_amount) / parseInt(loan?.loan_duration);
    const interestAmount = monthpay?.amount - installMentWithoutInterest;

    const getCashback = () => {
      if (monthpay?.status !== "Paid" || monthpay?.has_rollover) return 0;

      const lastPayDate = payment_records?.[payment_records?.length - 1]?.date;
      // The loan was fully paid before the the payment month
      if (isOneMonthApart(monthpay?.date, lastPayDate)) {
        return interestAmount;
      }

      // Paidup within the same month before the actual payment date
      if (isSameMonthAndLater(monthpay?.date, lastPayDate)) {
        return interestAmount * 0.08;
      }

      return 0;
    };

    return {
      total_paid: totalPaid,
      total_remaining: totalRemaining < 0 ? 0 : totalRemaining,
      total_rollover: monthpay?.has_rollover ? monthpay.rollover_amount : 0,
      total_cashback: getCashback(),
      rollover_status: monthpay?.rollover_status,
      total_remainig_rollover: monthpay?.remaining_rollover,
    };
  };

  const handlePayRollover = (id, amount) => {
    setLoading(true);
    if (!shouldAlloWActions) {
      dispatch(raisePermissionError());
      setLoading(false);
      return;
    }
    if (activePartialPay === "rollover") {
      dispatch(confirmRolloverPayment(id, amount)).then(() => {
        onTriggerRefresh();
        setLoading(false);
      });
    } else {
      if (window.confirm("Please confirm you would like to pay rollover")) {
        dispatch(confirmRolloverPayment(id, amount)).then(() => {
          onTriggerRefresh();
          setLoading(false);
        });
      }
    }

    setActiveItemId(undefined);
    setActivePartialPay();
    setPartialAmount(undefined);
  };

  const handleWaiveUnwaiveRollover = (id, shouldWaive, amount) => {
    setLoading(true);
    if (!shouldAlloWActions) {
      dispatch(raisePermissionError());
      setLoading(false);
      return;
    }
    if (
      window.confirm(
        "Please confirm you would like to waive/unwaive payment of rollover"
      )
    ) {
      dispatch(waiveUnwaiveRolloverPayment(id, shouldWaive, amount)).then(
        () => {
          onTriggerRefresh();
          setLoading(false);
        }
      );
    }
  };

  const handlePayment = (id, amount, type) => {
    setLoading(true);
    if (!shouldAlloWActions) {
      dispatch(raisePermissionError());
      setActiveItemId(undefined);
      setActivePartialPay();
      setPartialAmount(undefined);
      setLoading(false);
      return;
    }
    if (type === "all") {
      if (
        !window.confirm("Please confirm approval of all remaining payments")
      ) {
        return;
      }
    }

    dispatch(confirmPayment({ monthly_pay_id: id, amount }))?.then(() => {
      onTriggerRefresh();
      setActiveItemId(undefined);
      setLoading(false);
    });
  };

  const handleDeletePayRecord = (id) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      dispatch(deletePaymentRecord(id)).then(() => {
        onTriggerRefresh();
        setLoading(false);
      });
    }
  };

  const handleDeleteRolloverPay = (id) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      dispatch(deleteRolloverPayment(id)).then(() => {
        onTriggerRefresh();
        setLoading(false);
      });
    }
  };

  const RenderHeader = ({ pay }) => (
    <div className="d-flex justify-content-between align-items-center w-100">
      <span>{pay?.date}</span>
      <span>₦ {Math.round(pay?.amount).toLocaleString()}</span>
      <span>{pay?.status}</span>
    </div>
  );

  return (
    <>
      <hr />
      {loan?.monthly_payment_data?.length > 0 ? (
        <Collapse
          bordered={false}
          className={classNames(
            "repayment_collapse_wrapper loan-detail-collapse"
          )}
        >
          {loan?.monthly_payment_data?.map((instance) => {
            const {
              rollover_status,
              total_cashback,
              total_paid,
              total_remaining,
              total_rollover,
              total_remainig_rollover,
            } = getPayMetadata(instance);
            const paidUp = instance?.status === "Paid";

            return (
              <Panel
                className={instance?.status}
                key={instance?.id}
                header={<RenderHeader pay={instance} />}
              >
                <div>
                  <strong>Accumulations</strong>
                  <div>
                    Amount Paid: ₦ {Number(total_paid)?.toLocaleString()}
                  </div>
                  <div>
                    Amount Remaining: ₦{" "}
                    {Number(total_remaining)?.toLocaleString()}
                  </div>
                  {instance?.has_rollover && (
                    <div>
                      Amount Rollover: ₦{" "}
                      {Number(total_rollover)?.toLocaleString()}
                    </div>
                  )}
                  {paidUp && !instance?.has_rollover && !!total_cashback && (
                    <div>
                      Amount Cashback: ₦{" "}
                      {Number(total_cashback)?.toLocaleString()}
                    </div>
                  )}
                </div>

                <>
                  <hr />
                  <div className="mt-3">
                    <strong>Payments</strong>
                    {instance?.payment_records?.length ? (
                      instance?.payment_records?.map((partialInst) => (
                        <div className="d-flex justify-content-between">
                          <span>
                            ₦ {Number(partialInst?.amount).toLocaleString()}
                          </span>{" "}
                          -{" "}
                          <span>
                            {partialInst.date}{" "}
                            {shouldAlloWActions && (
                              <span
                                role="button"
                                onClick={() =>
                                  handleDeletePayRecord(partialInst?.id)
                                }
                                className="ml-2"
                              >
                                <i className="fas fa-trash"></i>
                              </span>
                            )}
                          </span>
                        </div>
                      ))
                    ) : (
                      <div>No Payments recorded</div>
                    )}
                  </div>

                  {!paidUp && (
                    <>
                      <div className="actions d-flex justify-content-between mt-4">
                        <button
                          className="btn btn-sm btn-primary"
                          disabled={loading}
                          onClick={() =>
                            handlePayment(
                              instance?.id,
                              Number(total_remaining),
                              "all"
                            )
                          }
                        >
                          Pay remaining
                        </button>

                        <button
                          className="btn btn-sm btn-primary"
                          disabled={loading}
                          onClick={() => {
                            setActivePartialPay("monthly");
                            setActiveItemId(instance?.id);
                          }}
                        >
                          Pay partial
                        </button>
                      </div>
                    </>
                  )}
                </>
                {/* )} */}

                {instance?.has_rollover && (
                  <div>
                    <hr />
                    <strong>Rollover</strong>

                    <div className="d-flex flex-column">
                      <span>
                        Amount: ₦ {Number(total_rollover)?.toLocaleString()}
                      </span>
                      <span>Status: {rollover_status}</span>

                      {instance?.rollover_payment_records?.length ? (
                        instance?.rollover_payment_records?.map(
                          (partialInst) => (
                            <div className="d-flex justify-content-between mt-2">
                              <div>{partialInst.waived ? "Waive" : "Paid"}</div>
                              <span>
                                ₦ {Number(partialInst?.amount).toLocaleString()}
                              </span>{" "}
                              <span>
                                {partialInst.date}

                                {shouldAlloWActions && (
                                  <span
                                    role="button"
                                    onClick={() =>
                                      handleDeleteRolloverPay(partialInst?.id)
                                    }
                                    className="ml-2"
                                  >
                                    <i className="fas fa-trash"></i>
                                  </span>
                                )}
                              </span>
                            </div>
                          )
                        )
                      ) : (
                        <div className="mt-2">No Payments recorded</div>
                      )}

                      <div className="mt-2 d-flex justify-content-between">
                        {rollover_status !== "Paid" &&
                          rollover_status !== "Waived" && (
                            <button
                              className="btn btn-sm btn-primary w-content"
                              disabled={loading}
                              onClick={() =>
                                handlePayRollover(
                                  instance?.id,
                                  total_remainig_rollover
                                )
                              }
                            >
                              Pay Remaining
                            </button>
                          )}

                        {rollover_status !== "Paid" &&
                          rollover_status !== "Waived" && (
                            <button
                              className="btn btn-sm btn-primary w-content"
                              disabled={loading}
                              onClick={() => {
                                setActiveItemId(instance?.id);
                                setActivePartialPay("rollover");
                              }}
                            >
                              Pay Partial
                            </button>
                          )}

                        {rollover_status !== "Paid" &&
                          rollover_status !== "Waived" && (
                            <button
                              className="btn btn-sm btn-primary w-content"
                              disabled={loading}
                              onClick={() =>
                                handleWaiveUnwaiveRollover(
                                  instance?.id,
                                  true,
                                  total_remainig_rollover
                                )
                              }
                            >
                              Waive Rollover
                            </button>
                          )}

                        {rollover_status === "Waived" && (
                          <button
                            className="btn btn-sm btn-primary w-content"
                            disabled={loading}
                            onClick={() =>
                              handleWaiveUnwaiveRollover(
                                instance?.id,
                                false,
                                total_remainig_rollover
                              )
                            }
                          >
                            Unwaive Rollover
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {paidUp && !instance?.has_rollover && !!total_cashback && (
                  <div>
                    <hr />
                    <strong>Cashback</strong>

                    <div className="d-flex flex-column">
                      <span>
                        Amount: ₦ {Number(total_cashback)?.toLocaleString()}
                      </span>
                    </div>
                  </div>
                )}
              </Panel>
            );
          })}
        </Collapse>
      ) : (
        <>
          There are no repayments available for this loan. It should be
          available after loan disbursement
        </>
      )}

      <Modal
        onOk={() => {
          if (activePartialPay === "monthly") {
            handlePayment(activeItemId, partialAmount);
            return;
          }

          if (activePartialPay === "rollover") {
            handlePayRollover(activeItemId, partialAmount);
          }
        }}
        onCancel={() => {
          setActiveItemId(undefined);
          setPartialAmount(undefined);
        }}
        title="Amount to be paid"
        visible={!!activeItemId}
      >
        <Input
          onChange={(e) => setPartialAmount(e.target.value)}
          value={partialAmount}
          type="number"
        />
      </Modal>
    </>
  );
};

export { LoanRepaymentPlan };
