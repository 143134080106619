import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { setDateFilterAction, getPersistedLoansWithParams } from "../store/loans";
import { getIdFieldAndId } from "../utils";

const useDashboardLoanList = () => {
  const {
    loans: { filterDate, loans },
  } = useSelector((state) => ({
    users: state.users,
    loans: state.loans,
  }));
  const dispatch = useDispatch();
  const { orgType, orgId } = useParams();
  const [listRefreshTrigger, setListRefreshTrigger] = useState(false);
  const [loading, setLoading] = useState(false);
  const { org_id_field, org_id } = getIdFieldAndId(orgType, orgId)

  const handleListRefreshTrigger = () =>
    setListRefreshTrigger((current) => !current);

  useEffect(async () => {
    let queryString = `start_date=${filterDate?.[0].format(
      "YYYY/MM/DD" || ""
    )}&end_date=${filterDate?.[1].format("YYYY/MM/DD") || ""}`;

    setLoading(true);
    if (org_id_field) {
      queryString = queryString.concat(`&${org_id_field}=${org_id}`);
    }

    dispatch(getPersistedLoansWithParams(org_id_field, org_id, queryString)).then(
      () => {
        setLoading(false);
      }
    );
  }, [listRefreshTrigger, filterDate]);

  const handleFilterChange = (range) => dispatch(setDateFilterAction(range));

  return {
    listData: loans?.[org_id_field]?.[org_id],
    id: orgId,
    handleListRefreshTrigger,
    loading,
    setFilterDate: handleFilterChange,
    filterDate,
  };
};

export { useDashboardLoanList };
