import React, { useState } from "react";
import { Collapse } from "antd";
import { Link } from "react-router-dom";

const { Panel } = Collapse;

const LoanRequestValidation = ({ eligibilityData, onValidate }) => {
  const [loading, setLoading] = useState(false);

  const handleValidate = async (e) => {
    e.preventDefault();
    setLoading(true);
    await onValidate?.();
    setLoading(false);
  };

  const RenderIcon = ({ value }) => {
    if (!eligibilityData?.showEligibilityCard) {
      return "-";
    }

    return value?.status ? (
      <i className="fas fa-check-circle icon-success"></i>
    ) : (
      <Link to={`/loans/${value?.loan_id}`} className="icon-danger">
        <i className="fas fa-exclamation-triangle icon-danger"></i> View loan
      </Link>
    );
  };

  return (
    <>
      <hr />
      <Collapse
        defaultActiveKey="1"
        bordered={false}
        className="repayment_collapse_wrapper"
      >
        <Panel
          key="1"
          header={
            <div className="d-flex align-items-center justify-content-between">
              <div className="mr-2">Check loan request validity </div>
              {loading ? (
                <div className="d-flex justify-content-center align-items-center my-2">
                  <i class="fas fa-spinner fa-spin "></i>{" "}
                </div>
              ) : eligibilityData?.isEligible ? (
                <i className="fas fa-check-circle icon-success"></i>
              ) : (
                <i className="fas fa-exclamation-triangle icon-danger"></i>
              )}
            </div>
          }
        >
          <div className="repayment-item d-flex mb-2">
            <div className="mr-3 purple">Client has no outstanding loan:</div>{" "}
            <RenderIcon value={eligibilityData["client_has_no_outstanding"]} />
          </div>

          <div className="repayment-item d-flex mb-2">
            <div className="mr-3 purple">Client has no unpaid rollover:</div>{" "}
            <RenderIcon value={eligibilityData["client_has_unpaid_rollover"]} />
          </div>

          <div className="repayment-item d-flex mb-2">
            <div className="mr-3 purple">
              Guarantor has no outstanding loan:
            </div>{" "}
            <RenderIcon
              value={eligibilityData["guarantor_has_no_outstanding"]}
            />
          </div>

          <div className="repayment-item d-flex mb-2">
            <div className="mr-3 purple">
              Guarantor has not guaranteed an outstanding loan:
            </div>{" "}
            <RenderIcon
              value={
                eligibilityData["guarantor_has_not_guaranteed_outstanding"]
              }
            />
          </div>

          <div className="repayment-item d-flex mb-2">
            <div className="mr-3 purple">
              Collateral is not used in an ongoing loan:
            </div>{" "}
            <RenderIcon
              value={eligibilityData["collateral_not_used_in_ongoing_loan"]}
            />
          </div>

          <button
            onClick={handleValidate}
            className="mt-3 btn btn-sm btn-secondary"
          >
            Validate Loan
          </button>
        </Panel>
      </Collapse>
    </>
  );
};

export { LoanRequestValidation };
