import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import LoginImg from "assets/img/login.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import { handleLoginAction } from "../store/users";

const Login = () => {
  const [returnTo, setReturnTo] = useState(null);
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const isProd = process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION';

  const { user } = useSelector((state) => state.users);
  const history = useHistory();
  const location = useLocation()
  const dispatch = useDispatch();

  const handleFinish = async (values) => {
    setSubmitting(true);
    await dispatch(handleLoginAction(values));
    setSubmitting(false)
  };

  useEffect(() => {
    // check for returnTo
    const returnTo = location.state?.returnTo || new URLSearchParams(location.search).get('returnTo');

    if (returnTo) {
      setReturnTo(returnTo)
    }
  }, [location])

  useEffect(() => {
    // check if the user is authenticated
    if (!!user) {
      history.push(returnTo || '/');
    }
  }, [user, returnTo, history])

  return (
    <Container fluid className="login-page-wrap">
      <Row>
        <Col md="6" className="login-page-form-wrap">
          <Card className="p-4 border-none">
            <Card.Title className="mb-3">
              <h3 className="page-title mb-2">Welcome back</h3>
              Please provide your details!.
            </Card.Title>
            <Form form={form} onFinish={handleFinish}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please provide email",
                  },
                ]}
              >
                <Input
                  className="form-control"
                  type="email"
                  placeholder="Enter email"
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please provide password",
                  },
                ]}
              >
                <Input
                  className="form-control"
                  type="password"
                  placeholder="Enter password"
                />
              </Form.Item>

              <Row className="mt-3">
                <Col>
                  <Button
                    variant="primary"
                    className="px-5 w-100"
                    type="submit"
                    disabled={submitting}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>

            {!isProd && (
              <div className="p-2 mt-3 warning-item d-flex align-items-center">
                <i className="fas fa-info-circle warning_event mr-2"></i>

                <p className="mb-0">Test environment</p>
              </div>
            )}
          </Card>
        </Col>
        <Col md="6" className="d-none d-md-flex login-page-img-wrap">
          <img src={LoginImg} alt="moon" />
        </Col>
      </Row>
    </Container>
  );
};

export { Login };
