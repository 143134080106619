import React, { useEffect, useState } from "react";
import { Form, Input, Select } from "antd";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useOrgAndRelatedOrgInput } from "../hooks";
import { reportCreateAction } from "../store/org";
import { INCOME_ORG_TYPE } from "./CreateOrgLevel";

const ReportsForm = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const {
    onSelectOrgType,
    loadingOrg,
    relatedOrgOption,
    selectFieldName,
    orgType,
    handleFetchUserData,
  } = useOrgAndRelatedOrgInput(true);
  const [reportType, setReportType] = useState("");
  const { user } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    if (reportType === 'staff') {
        handleFetchUserData()
    }
  }, [reportType])

  const handleSubmit = async (values) => {
    setLoading(true)
    await dispatch(reportCreateAction({
      ...values,
      reporter_id: user.id
    }))
    setLoading(false)
    form.resetFields()
  };

  return (
    <Container fluid>
      <h3 className="page-title mb-4">Add Report</h3>

      <Form form={form} onFinish={handleSubmit}>
        <Row>
          <Col md="6">
            <Form.Item
              name="report_type"
              rules={[
                {
                  required: true,
                  message: "Please select an option",
                },
              ]}
              label="Type of report"
            >
              <Select
                showSearch
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input?.toLowerCase()) >=
                  0
                }
                placeholder="Staff / Org"
                allowClear
                onSelect={setReportType}
              >
                <Select.Option value="staff" key={1}>
                  Staff Report
                </Select.Option>
                <Select.Option value="org" key={2} disabled={user?.position === "BDO"}>
                  Org Report
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          {reportType === "org" && (
            <Col className="" md="6">
              <Form.Item
                label="Select Org Level"
                rules={[
                  {
                    required: true,
                    message: "Please select a CATEGORY",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select org level"
                  optionFilterProp="children"
                  className="custom-input"
                  disabled={!reportType}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={onSelectOrgType}
                >
                  {INCOME_ORG_TYPE.map(
                    ({ id, label, permissionLevel }) =>
                      user?.permissionLevel >= permissionLevel && (
                        <Select.Option value={id} key={id}>
                          {label}
                        </Select.Option>
                      )
                  )}
                </Select>
              </Form.Item>
            </Col>
          )}

          {!!relatedOrgOption?.length && (
            <Col md="6">
              <Form.Item
                name={selectFieldName}
                rules={[
                  {
                    required: true,
                    message: "Please an an option",
                  },
                ]}
                label={`${orgType}`}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  className="custom-input"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                  allowClear
                >
                  {relatedOrgOption.map(({ id, name }) => (
                    <Select.Option value={id} key={id}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

          <Col className="" md="6">
            <Form.Item
              name="date"
              label="Date"
              rules={[
                {
                  required: true,
                  message: "Please provide date",
                },
              ]}
            >
              <Input placeholder="Date" type="date" className="form-control" />
            </Form.Item>
          </Col>

          <Col className="" md="12">
            <Form.Item
              name="content"
              label="Report"
              rules={[
                {
                  required: true,
                  message: "Please provide date",
                },
              ]}
            >
              <Input.TextArea
                placeholder="Add your report here"
                className="form-control"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <Button
              variant="primary"
              className="px-5"
              disabled={loadingOrg || loading}
              type="submit"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export { ReportsForm };
