import { Input, Modal, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ALL_USER_POSITIONS } from "utils";
import { USER_POSITIONS } from "utils";

export const SalaryPaymentModal = ({ isOpen, toggleOpen, onSubmit }) => {
  const [grossPay, setGrossPay] = useState();
  const [taxPercentage, setTaxPercentage] = useState();
  const [taxAmount, setTaxAmount] = useState();
  const [cashSecurity, setCashSecurity] = useState();
  const [otherDeductions, setOtherDeductions] = useState();
  const [deductionReasons, setDeductionReasons] = useState();
  const [netPay, setNetPay] = useState();

  const getTaxAmount = () =>
    (Number(taxPercentage) || 0) * 0.01 * (grossPay || 0);

  useEffect(() => {
    if (grossPay) {
      setNetPay(
        grossPay -
          ((Number(taxAmount) || 0) +
            (Number(cashSecurity) || 0) +
            (Number(otherDeductions) || 0))
      );
    }
  }, [grossPay, taxAmount, cashSecurity, otherDeductions, deductionReasons]);

  useEffect(() => {
    setTaxAmount(() => getTaxAmount());
  }, [taxPercentage]);

  const handleMakePayment = () => {
    onSubmit?.({
      gross_pay: grossPay,
      tax_percentage: taxPercentage,
      tax_amount: taxAmount,
      cash_security: cashSecurity,
      other_deductions: otherDeductions,
      deduction_reason: deductionReasons,
      net_pay: netPay,
    });

    toggleOpen();

    setGrossPay();
    setTaxAmount();
    setTaxPercentage();
    setCashSecurity();
    setOtherDeductions();
    setDeductionReasons();
    setNetPay();
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={() => toggleOpen()}
      title="Add payment"
      okText="Submit"
      onOk={() => handleMakePayment()}
      className="d-flex flex-col"
    >
      <span className="mb-3">Gross Pay (₦)</span>
      <Input
        placeholder="Gross Pay (₦)"
        className="form-control mb-2 mt-2"
        type="number"
        value={grossPay}
        onChange={(e) => setGrossPay(e.target.value)}
      />

      <span className="mb-3">Tax Percentage (%)</span>
      <Input
        placeholder="Tax Percentage (%)"
        className="form-control mb-2 mt-2"
        type="number"
        value={taxPercentage}
        onChange={(e) => setTaxPercentage(e.target.value)}
      />

      <span className="mb-3">Tax Amount (₦)</span>
      <Input
        placeholder="Tax Percentage (%)"
        className="form-control mb-2 mt-2"
        type="number"
        value={taxAmount}
        onChange={(e) => setTaxAmount(e.target.value)}
      />

      <span className="">Cash Security (₦)</span>
      <Input
        placeholder="Cash Security (₦)"
        className="form-control mb-2 mt-2"
        type="number"
        value={cashSecurity}
        onChange={(e) => setCashSecurity(e.target.value)}
      />

      <span className="mb-3">Other deductions (₦)</span>
      <Input
        placeholder="Other Deductions(₦)"
        className="form-control mb-2 mt-2"
        type="number"
        value={otherDeductions}
        onChange={(e) => setOtherDeductions(e.target.value)}
      />

      <span className="mb-3">Deduction Reasons</span>
      <Input
        placeholder="Deduction Reasons"
        className="form-control mb-2 mt-2"
        type="text"
        value={deductionReasons}
        onChange={(e) => setDeductionReasons(e.target.value)}
      />

      <span className="mb-3">Net Pay (₦)</span>
      <div className="form-control ant-input-disabled mt-2">
        ₦ {netPay ? Number(netPay)?.toLocaleString() : ""}
      </div>
    </Modal>
  );
};

export const NewQueryModal = ({ isOpen, toggleOpen, onSubmit }) => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleAddAward = () => {
    onSubmit?.({ subject, message });
    toggleOpen();
    setSubject("");
    setMessage("");
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={() => toggleOpen()}
      title="Add new query"
      okText="Submit"
      onOk={() => handleAddAward()}
    >
      <Input
        placeholder="Subject"
        className="form-control mb-2"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      />

      <Input.TextArea
        placeholder="Message"
        className="form-control mb-2"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
    </Modal>
  );
};

export const RenderAddAward = ({ isOpen, toggleOpen, onSubmit }) => {
  const [awardTitle, setAwardTitle] = useState("");
  const [awardDescription, setAwardDescription] = useState("");

  const handleAddAward = () => {
    onSubmit?.({ title: awardTitle, description: awardDescription });
    toggleOpen();
    setAwardDescription("");
    setAwardTitle("");
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={() => toggleOpen()}
      title="Add new Award"
      okText="Submit"
      onOk={() => handleAddAward()}
    >
      <Input
        placeholder="Award Title"
        className="form-control mb-2"
        value={awardTitle}
        onChange={(e) => setAwardTitle(e.target.value)}
      />

      <Input
        placeholder="Award Description"
        className="form-control mb-2"
        value={awardDescription}
        onChange={(e) => setAwardDescription(e.target.value)}
      />
    </Modal>
  );
};

export const StaffPromotionModal = ({
  isOpen,
  toggleOpen,
  onSubmit,
  currentPosition,
  currentUser,
}) => {
  const [newPosition, setNewPosition] = useState("");
  const [promotionDescription, setPromotionDescription] = useState("");
  const [promotionEffectiveDate, setPromotionEffectiveDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const handlePromoteStaff = () => {
    onSubmit?.({
      new_position: newPosition,
      reason: promotionDescription,
      effective_date: promotionEffectiveDate,
    });
    toggleOpen();
    setPromotionDescription("");
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={() => toggleOpen()}
      title="Change staff position"
      okText="Submit"
      onOk={() => handlePromoteStaff()}
    >
      <div className="d-flex flex-column">
        <span className="mb-2">Select new position</span>
        <Select
          showSearch
          placeholder="Select position"
          optionFilterProp="children"
          className="custom-input"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={setNewPosition}
          value={newPosition}
        >
          {USER_POSITIONS.filter((val) => val.id !== currentPosition).map((val) => (
            <Select.Option
              value={val.id}
              key={val.id}
              disabled={
                currentUser.permissionLevel < val.permissionLevel
              }
            >
              {val.id}
            </Select.Option>
          ))}
        </Select>
      </div>

      <div className="d-flex flex-column mt-3">
        <span className="mb-2">Provide description (Optional)</span>
        <Input.TextArea
          placeholder="Promotion Description"
          className="form-control mb-2"
          value={promotionDescription}
          onChange={(e) => setPromotionDescription(e.target.value)}
        />
      </div>

      <div className="d-flex flex-column mt-3">
        <span className="mb-2">Promotion effective date</span>
        <Input
          placeholder="Effective Date"
          className="form-control mb-2"
          type="date"
          value={promotionEffectiveDate}
          onChange={(e) => setPromotionEffectiveDate(e.target.value)}
        />
      </div>
    </Modal>
  );
};
