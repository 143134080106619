import { Spin } from "antd";
import classNames from "classnames";
import moment from "moment";
import React from "react";
import { Card } from "react-bootstrap";
import { capitalizeFirstLetter } from "../utils";

const RenderList = ({ data = {}, extraContent, exclude = [], loading }) => {
  const lowerCaseExclude = exclude?.map((val) => val?.toLocaleLowerCase());
  const keys = Object.keys(data).filter(
    (val) => !lowerCaseExclude.includes(val?.toLocaleLowerCase())
  );

  return (
    <Card className="p-3">
      <div className="d-flex justify-content-center">
        {loading && <Spin className="ant-loading-spinner" spinning={loading} />}
      </div>
      <ul className={classNames("custom-list-wrapper", loading && 'loading-render-list')}>
        {
          keys?.map(
            (val) =>
              typeof data[val] !== "object" && (
                <li className="custom-list-item p-2 d-flex justify-content-between">
                  <strong className="custom-list-item-title">
                    {capitalizeFirstLetter(val)?.split("_").join(" ")}
                  </strong>
                  {val === "last_login" ? (
                    moment(data[val]).format("LLL")
                  ) : (
                    <span className="text-muted">{data[val]}</span>
                  )}
                </li>
              )
          )
        }
      </ul>

      {<div className="custom-list-footer">{extraContent}</div>}
    </Card>
  );
};

export { RenderList };
