import React, { useEffect } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { Sidebar, AdminNavbar } from "../components";
import { useRoutes } from "../hooks";
import { isArray } from "lodash";

const Admin = () => {
  const { routes } = useRoutes();
  const location = useLocation();
  const mainPanel = React.useRef(null);
  const { user } = useSelector((state) => state.users);

  const getRoutes = (route) =>
    route.map((prop, key) =>
      prop.subLayouts
        ? getRoutes(prop.subLayouts)
        : prop.permissionLevel <= user.permissionLevel &&
          (isArray(prop.path) ? (
            prop.path?.map((path) => (
              <Route
                path={path}
                render={(props) => <prop.component {...props} {...prop} />}
                key={key}
                exact
              />
            ))
          ) : (
            <Route
              path={prop.path}
              render={(props) => <prop.component {...props} {...prop} />}
              key={key}
              exact
            />
          ))
    );

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      let element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);

  return (
    <>
      <div className="wrapper">
        <Sidebar routes={routes} authUser={user} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Switch>{getRoutes(routes)}</Switch>
          </div>
        </div>
      </div>
    </>
  );
};

export { Admin };
