import { DatePicker, Input, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { NumberParam, useQueryParam } from "use-query-params";
import {
  getSummationOfFields,
  getIdFieldAndId
} from "../utils";
import { ModifiedAntTable } from "./ModifiedAntTable";
import { deleteSiezedAssetsAction, getSiezedAssets } from "../store/org";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useFilterDateDropdown, useListTitle } from "../hooks";
import { Col, Row } from "react-bootstrap";

const STATUS = ["Sold", "Retrieved", "Held"];

const SeizedAssetsList = () => {
  const [loading, setLoading] = useState();
  const [page, setPage] = useQueryParam("page", NumberParam);
  const [assets, setAssets] = useState([]);
  const [summedData, setSummedData] = useState({});
  const [filteredAssets, setFilteredAssets] = useState();
  const [filterDate, setFilterDate] = useState([
    moment().subtract(10, "days"),
    moment(),
  ]);
  const [filterStatus, setFilterStatus] = useState();
  const [filterName, setFilterName] = useState();
  const [filterSiezorName, setFilterSiezorName] = useState();
  const [refreshTrigger, setRefreshTrigger] = useState(false)
  const dispatch = useDispatch();
  const { orgType, orgId } = useParams();
  const {
    users: { user },
  } = useSelector((state) => ({
    users: state.users,
  }));
  const { renderFilter } = useFilterDateDropdown({ setFilterDate })
  const { fullListName } = useListTitle("seized assets")
  const { org_id_field, org_id } = getIdFieldAndId(orgType, orgId)

  useEffect(() => {
    setFilteredAssets(assets);
  }, [assets]);

  useEffect(() => {
    setFilteredAssets(
      assets?.filter(
        (val) =>
          val?.owner?.fullname
            ?.toLowerCase()
            ?.includes(filterName?.toLowerCase() || "") &&
          (val?.status?.includes(filterStatus) || !filterStatus) &&
          val?.siezor
            ?.toLowerCase()
            ?.includes(filterSiezorName?.toLowerCase() || "")
      )
    );
  }, [filterStatus, filterName, filterSiezorName]);

  useEffect(() => {
    setSummedData(
      getSummationOfFields({
        data: filteredAssets,
        fieldsToSum: ["amount", "quantity", "amount_retrieved", "amount_sold"],
      })
    );
  }, [filteredAssets]);

  useEffect(() => {
    const queryString = `start_date=${filterDate[0].format(
      "YYYY/MM/DD"
    )}&end_date=${filterDate[1].format("YYYY/MM/DD")}&${org_id_field}=${org_id}`;

    dispatch(getSiezedAssets(queryString)).then((res) => {
      setAssets(res);
      setLoading(false);
    });
  }, [filterDate, refreshTrigger]);

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this withdrawal from record?.")) {
      await dispatch(deleteSiezedAssetsAction(id));
      setRefreshTrigger(current => !current)
    }
  };

  const tableColumn = [
    {
      title: "S/N",
      dataIndex: "id",
      render: (text, record, index) =>
        ((Number(page) || 1) - 1) * 50 + (index + 1),
      summary: "Summation",
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (text, record) => <div>{record?.date}</div>,
      summary: "-",
    },
    {
      title: "Asset Name",
      dataIndex: "name",
      render: (text, record) => (
        <Link to={`/seizedassets/${record.id}/`}>
          <i className="fa fa-link" aria-hidden="true"></i> {`${record?.name}`}
        </Link>
      ),
      summary: "-",
    },
    {
      title: "Owner",
      render: (text, record, index) => record?.owner?.fullname,
      summary: "-",
    },
    {
      title: "Siezor",
      dataIndex: "siezor",
      summary: "-",
    },
    {
      title: "Amount owed",
      dataIndex: "amount",
      render: (text, record) => (
        <div>{Number(record?.amount || 0).toLocaleString()}</div>
      ),
      summary: summedData["amount"],
    },
    {
      title: "Quantity",
      render: (text, record) => <div>{record?.quantity}</div>,
      summary: summedData["quantity"],
    },
    {
      title: "status",
      render: (text, record) => (
        <div className="d-flex gap-1">
          {record.status?.map((val) => (
            <div
              className={classNames(
                "status-badge badge badge-sm mr-1",
                val !== "Held" ? "badge-success" : "badge-warning"
              )}
            >
              {val}
            </div>
          ))}
        </div>
      ),
      summary: "-",
    },
    {
      title: "Amount Sold",
      render: (text, record, index) =>
        Number(record?.amount_sold).toLocaleString(),
      summary: summedData["amount_sold"],
    },
    {
      title: "Amount Retrieved",
      render: (text, record, index) =>
        Number(record?.amount_retrieved).toLocaleString(),
      summary: summedData["amount_retrieved"],
    },
  ];

  if (["DR", "SM", "ZM"].includes(user?.position)) {
    tableColumn.push({
      title: "",
      dataIndex: "id",
      render: (text, record) => (
        <span role="button" onClick={() => handleDelete(record?.id)}>
          <i className="fas fa-trash"></i>
        </span>
      ),
      summary: "-",
    });
  }

  return (
    <div>
      <h3 className="page-title mb-4">{fullListName}</h3>

      <Input.Group compact className="mb-3 filter-panel">
        <Select
          allowClear
          className="default-ant-select"
          style={{ width: "20%" }}
          placeholder="Filter by status"
          value={filterStatus}
          onChange={(val) => setFilterStatus(val)}
        >
          {STATUS?.map((val, index) => (
            <Select.Option value={val} key={index}>
              {val}
            </Select.Option>
          ))}
        </Select>
        <Input
          style={{ width: "40%" }}
          placeholder="Filter by siezor's name"
          value={filterSiezorName}
          onChange={(e) => setFilterSiezorName(e.target.value)}
        />
        <Input
          style={{ width: "40%" }}
          placeholder="Filter by owner's name"
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
        />
      </Input.Group>

      <Row>
        <Col className="d-flex">
          {renderFilter()}
          <DatePicker.RangePicker
            style={{ width: "100%" }}
            allowClear={false}
            onChange={(val) => setFilterDate(val)}
            className="mb-3"
            value={filterDate}
          />
        </Col>
      </Row>

      <ModifiedAntTable
        loading={loading}
        scroll={{ x: "max-content" }}
        columns={tableColumn}
        dataSource={filteredAssets}
        showSummary={true}
        bordered
        pagination={{
          defaultCurrent: page,
          current: page,
          defaultPageSize: 50,
          hideOnSinglePage: true,
          onChange: (current) => setPage(current),
        }}
      />
    </div>
  );
};

export { SeizedAssetsList };
