import { parse } from "query-string";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import {
  setClientDateFilterAction,
  getClientsWithParams,
  getStaffsWithParams,
  getAllGuarantor,
} from "../store/clients";
import { getIdFieldAndId } from "../utils";

const useDashboardClientList = (all_time) => {
  const { status: routeStatus } = parse(window.location.search);
  const {
    users: { user },
    client: { filterDate, clients, loading },
  } = useSelector((state) => ({
    users: state.users,
    client: state.clients,
  }));
  const dispatch = useDispatch();
  const { orgType, orgId } = useParams();
  const { pathname } = useLocation();
  const [filterName, setFilterName] = useState("");
  const [filterStatus, setFilterStatus] = useState(routeStatus);
  const [staffs, setStaffs] = useState([]);
  const [guarantorList, setGurantorList] = useState([]);

  const { org_id_field, org_id } = getIdFieldAndId(orgType, orgId);

  // useful for loan request form
  const shouldShowViewType =
    !pathname?.includes("staff") &&
    !pathname?.includes("branch") &&
    !user?.position === "BDO";

  const fetchClientData = () => {
    let client_querystring = "";

    if (filterDate?.length && !all_time) {
      if (filterStatus === "ACTIVE") {
        client_querystring = client_querystring?.concat(
          `loan_start_date=${filterDate?.[0]?.format(
            "YYYY/MM/DD" || ""
          )}&loan_end_date=${filterDate?.[1]?.format("YYYY/MM/DD") || ""}`
        );
      } else {
        client_querystring = client_querystring?.concat(
          `start_date=${filterDate?.[0]?.format("YYYY/MM/DD" || "")}&end_date=${
            filterDate?.[1]?.format("YYYY/MM/DD") || ""
          }`
        );
      }
    }

    client_querystring = client_querystring.concat(
      `&${org_id_field}=${org_id}`
    );
    dispatch(getClientsWithParams(org_id_field, org_id, client_querystring));
  };

  const handleListRefreshTrigger = () => {
    fetchClientData();
  };

  useEffect(async () => {
    fetchClientData();
  }, [filterDate, filterName, filterStatus, orgType]);

  useEffect(() => {
    dispatch(
      getStaffsWithParams(
        `${org_id_field === "handler_id" ? "user_id" : org_id_field}=${org_id}`,
        ""
      )
    ).then((result) => {
      setStaffs(result);
    });
  }, []);

  const handleFilterChange = (range) =>
    dispatch(setClientDateFilterAction(range));

  const handleGetGuarantors = async () => {
    let queryString = `${org_id_field}=${org_id}`;

    const res = await dispatch(getAllGuarantor(queryString));
    if (res) {
      setGurantorList(res);
    }
  };

  return {
    listData: clients?.[org_id_field]?.[org_id],
    id: org_id,
    handleListRefreshTrigger,
    loading,
    filterDate,
    setFilterDate: handleFilterChange,
    filterName,
    setFilterName,
    filterStatus,
    setFilterStatus,
    staffs,
    shouldShowViewType,
    handleGetGuarantors,
    guarantorList,
  };
};

export { useDashboardClientList };
