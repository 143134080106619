import React, { useEffect } from "react";
import { DatePicker } from "antd";
import { useState } from "react";
import moment from "moment";
import { useQueryParam, NumberParam } from "use-query-params";
import { PaymentDistributionDrawer } from "./PaymentDistributionDrawer";
import { ModifiedAntTable } from "./ModifiedAntTable";
import _ from "lodash";
import { getSummationOfFields, getTotal } from "../utils";
import { Json2CsvButton } from ".";

const PaymentDistributionTable = ({
  dataset,
  onDateRangeChange,
  loadingTableData,
}) => {
  // Defaults to 3 days from now
  const [dateRange, setDateRage] = useState([
    moment().subtract(4, "days"),
    moment(),
  ]);
  const [page, setPage] = useQueryParam("page", NumberParam);
  const [activeRecord, setActiveRecord] = useState();
  const [summedData, setSummedData] = useState({});

  const handleDateRageChange = (val) => {
    setDateRage(val);
    onDateRangeChange(val);
  };

  useEffect(() => {
    setSummedData(
      getSummationOfFields({
        data: dataset,
        fieldsToSum: [
          "realizable_installment",
          "realised_installment",
          "total_admin_fee",
          "total_processing_fee",
          "total_form_fee",
          "total_affidavit_fee",
          "total_insurance_fee",
          "prev_cash_in_hand_paid",
          "prev_cash_in_hand_dailypay",
          "approved_before_date_dailypay",
          "advanced_payment",
          "total",
          "cashInHand",
          "special_income",
          "org_expenses",
          "sold_assets",
          "retrieved_assets",
          "default_amount",
          "realizable_insterest",
          "realised_insterest",
          "rollover_paid",
          "approved_after_date_dailypay"
        ],
        fieldWithHandlers: {
          total: (rowInstance) => getTotal(rowInstance),
        },
      })
    );
  }, [dataset]);

  const columns = [
    {
      title: "S/N",
      dataIndex: "id",
      render: (text, record, index) =>
        ((Number(page) || 1) - 1) * 50 + (index + 1),
      summary: "Summations",
    },
    {
      title: "Date",
      dataIndex: "date",
      summary: "-",
    },
    {
      title: "Realizable",
      summary: [
        summedData?.["realizable_installment"],
        summedData?.["realizable_insterest"],
      ],
      children: [
        {
          title: "Installment (₦)",
          dataIndex: "realizable_installment",
          key: "realisable_installment",
          render: (_, data) =>
            Number(data?.realizable_installment || 0).toLocaleString(),
        },
        {
          title: "Interest (₦)",
          dataIndex: "realizable_insterest",
          key: "realizable_insterest",
          render: (_, data) =>
            Number(data?.realizable_insterest || 0).toLocaleString(),
        },
      ],
    },
    {
      title: "Realised",
      summary: [
        summedData?.["realised_installment"],
        summedData?.["realised_insterest"],
      ],
      children: [
        {
          title: "Installment (₦)",
          dataIndex: "realised_installment",
          key: "installment",
          render: (_, data) =>
            Number(data?.realised_installment || 0).toLocaleString(),
        },
        {
          title: "Interest (₦)",
          dataIndex: "realised_insterest",
          key: "realised_insterest",
          render: (_, data) =>
            Number(data?.realised_insterest || 0).toLocaleString(),
        },
      ],
    },
    // Previously paid in advance
    {
      title: "Arreas payment (₦)",
      summary: summedData?.["approved_before_date_dailypay"],
      render: (_text, data) =>
        Number(data?.approved_before_date_dailypay || 0).toLocaleString(),
    },
    {
      title: "Paid Rollover (₦)",
      render: (record) => Number(record?.rollover_paid || 0).toLocaleString(),
      summary: summedData?.["rollover_paid"],
    },
    {
      title: "Admin Fee (₦)",
      render: (record) => Number(record?.total_admin_fee || 0).toLocaleString(),
      summary: summedData?.["total_admin_fee"],
    },
    {
      title: "Form Fee (₦)",
      render: (record) => Number(record?.total_form_fee || 0).toLocaleString(),
      summary: summedData?.["total_form_fee"],
    },
    {
      title: "Affidavit Fee (₦)",
      render: (record) =>
        Number(record?.total_affidavit_fee || 0).toLocaleString(),
      summary: summedData?.["total_affidavit_fee"],
    },
    {
      title: "Insurance Fee (₦)",
      render: (record) =>
        Number(record?.total_insurance_fee || 0).toLocaleString(),
      summary: summedData?.["total_insurance_fee"],
    },
    {
      title: "Processing Fee (₦)",
      render: (record) =>
        Number(record?.total_processing_fee || 0).toLocaleString(),
      summary: summedData?.["total_processing_fee"],
    },
    {
      title: "Advance (₦)",
      summary: summedData?.["advanced_payment"],
      render: (_text, data) =>
        Number(data?.advanced_payment || 0).toLocaleString(),
    },
    {
      title: "Org Expenses (₦)",
      dataIndex: "org_expenses",
      key: "org_expenses",
      summary: summedData?.["org_expenses"],
      render: (_text, data) => Number(data?.org_expenses || 0).toLocaleString(),
    },
    {
      title: "Special Income (₦)",
      dataIndex: "special_income",
      key: "special_income",
      summary: summedData?.["special_income"],
      render: (_text, data) =>
        Number(data?.special_income || 0).toLocaleString(),
    },
    {
      title: "Asset Auction (₦)",
      dataIndex: "sold_assets",
      key: "sold_assets",
      summary: summedData?.["sold_assets"],
      render: (_text, data) => Number(data?.sold_assets || 0).toLocaleString(),
    },
    {
      title: "Asset Retrieve (₦)",
      dataIndex: "retrieved_assets",
      key: "retrieved_assets",
      summary: summedData?.["retrieved_assets"],
      render: (_text, data) =>
        Number(data?.retrieved_assets || 0).toLocaleString(),
    },
    {
      title: "Prev Defaults Recovered (₦)",
      dataIndex: "prev_cash_in_hand_paid",
      summary: summedData?.["prev_cash_in_hand_paid"],
      render: (_text, data) =>
        Number(data?.prev_cash_in_hand_paid || 0).toLocaleString(),
    },
    {
      title: "Total Default (₦)",
      dataIndex: "default_amount",
      key: "default_amount",
      summary: summedData?.["default_amount"],
      render: (_text, data) => Number(data?.default_amount).toLocaleString(),
    },
    {
      title: "Recovered Default (₦)",
      dataIndex: "approved_after_date_dailypay",
      key: "approved_after_date_dailypay",
      summary: summedData?.["approved_after_date_dailypay"],
      render: (_text, data) => Number(data?.approved_after_date_dailypay).toLocaleString(),
    },
    {
      title: "Total (₦)",
      dataIndex: "total",
      key: "total",
      summary: summedData?.["total"],
      render: (_, data) => Number(getTotal(data)).toLocaleString(),
    },
  ];

  return (
    <div className="payment-table">
      <div className="d-flex">
        <Json2CsvButton data={dataset} text="Download CSV" />

        <DatePicker.RangePicker
          allowClear
          style={{ width: "100%" }}
          value={dateRange}
          onChange={handleDateRageChange}
          className="mb-3"
        />
      </div>

      <ModifiedAntTable
        loading={loadingTableData}
        scroll={{ x: "max-content" }}
        columns={columns}
        dataSource={dataset}
        bordered
        className="dashboard_table"
        showSummary={true}
        pagination={{
          defaultCurrent: page,
          current: page,
          defaultPageSize: 50,
          hideOnSinglePage: true,
          onChange: (current) => setPage(current),
        }}
        onRow={(record, rowIndex) => ({
          onClick: () => setActiveRecord(record),
        })}
      />

      <PaymentDistributionDrawer
        activeRecord={activeRecord}
        setActiveRecord={setActiveRecord}
      />
    </div>
  );
};

export { PaymentDistributionTable };
