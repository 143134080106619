import { Descriptions, Tabs, Form, Select, Input } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useQueryParam, StringParam } from "use-query-params";
import { ImageUpload } from ".";
import { LoanRepaymentPlan } from "./LoanRepaymentPlan";
import { getTotalCashbackCount } from "../utils";

import { disbursePayment, getLoan } from "../store/loans";
import { updateClient, updateGuarantor } from "../store/clients";
import { showAlertAction } from "store/alert";

const { TabPane } = Tabs;

const LoanDetailPage = () => {
  const { id } = useParams();
  const { user } = useSelector((state) => state.users);
  const [form] = Form.useForm();
  const [loanData, setLoanData] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disbursementMethod, setDisbursementMethod] = useState("");
  const [activeView, setActiveView] = useQueryParam("view", StringParam);
  const dispatch = useDispatch();

  const handleUpload = (url, isClient) => {
    if (isClient) {
      dispatch(
        updateClient(loanData?.client?.id, { image: url }, "PATCH")
      ).then(() => {
        setTriggerRefresh((current) => !current);
      });
    } else {
      dispatch(
        updateGuarantor(loanData?.guarantor?.id, { image: url }, "PATCH")
      ).then(() => {
        setTriggerRefresh((current) => !current);
      });
    }
  };

  useEffect(async () => {
    setLoading(true);
    await dispatch(getLoan(id)).then((result) => setLoanData(result));
    setLoading(false);
  }, [triggerRefresh]);

  const handleTriggerRefresh = () => setTriggerRefresh((current) => !current);

  useEffect(() => {
    window.addEventListener("resize", () =>
      setIsMobile(() => window.innerWidth < 768)
    );

    return () =>
      window.removeEventListener("resize", () =>
        setIsMobile(() => window.innerWidth < 768)
      );
  }, []);

  const handleDisburse = async (values) => {
    if (!["DR", "HOA", "BHOA"].includes(user?.position)) {
      dispatch(
        showAlertAction({
          message: "You do not have the required permission to carry out this oparation.",
          level: "danger",
          isVisible: true,
        })
      );
      return
    }

    setLoading(true);
    await dispatch(disbursePayment(id, { ...values, id }));
    setTriggerRefresh((current) => !current);
    setLoading(false);
  };

  const renderLoanDetail = () => (
    <>
      <div className="d-flex mb-3 flex-column flex-sm-row">
        <div className="d-flex flex-column mr-0 mb-2 mr-sm-3">
          <h6>Client</h6>

          <Row>
            <Col>
              <ImageUpload
                uploadDir="clients"
                image={loanData?.client?.image}
                onImageUpload={(url) => handleUpload(url, true)}
                onDeleteImage={() => handleUpload(null, true)}
              />
            </Col>
          </Row>
        </div>

        <div className="d-flex flex-column mb-2">
          <h6>Guarantor</h6>
          <Row>
            <Col>
              <ImageUpload
                uploadDir="gurantors"
                image={loanData?.guarantor?.image}
                onImageUpload={(url) => handleUpload(url, false)}
                onDeleteImage={() => handleUpload(null, false)}
              />
            </Col>
          </Row>
        </div>
      </div>

      <Descriptions title="Loan Meta" layout="horizontal">
        <Descriptions.Item label="Credit Officer">
          {loanData?.client?.co_fullname || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Client">
          {loanData?.client?.fullname || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Guarantor">
          {loanData?.guarantor
            ? `${loanData?.guarantor?.surname || "-"} ${
                loanData?.guarantor?.firstname || "-"
              }`
            : ""}
        </Descriptions.Item>
        {loanData?.payment_method && (
          <Descriptions.Item label="Payment Method">
            {loanData?.payment_method || "-"}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Cheque Number">
          {loanData?.cheque_number || "-"}
        </Descriptions.Item>
        {loanData?.loan_status && (
          <Descriptions.Item label="Disbursement Status">
            {loanData?.loan_status === "AWAITING DISBURSMENT"
              ? "PENDING"
              : "DISBURSED"}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Loan Status">
          {loanData?.loan_status || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Request Date">
          {loanData?.creation_date || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Repayment Start Date">
          {loanData?.repayment_start_date || "-"}
        </Descriptions.Item>

        <Descriptions.Item label="Verified Name">
          {loanData?.verified_name || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Marital Status">
          {loanData?.marital_status || "-"}
        </Descriptions.Item>

        <Descriptions.Item label="House Address" span={2}>
          {loanData?.house_address || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Office Address" span={2}>
          {loanData?.office_address || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Guarantor Phone Number 1" span={2}>
          {loanData?.guarantor?.phone_number1 || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Guarantor Phone Number 2" span={2}>
          {loanData?.guarantor?.phone_number2 || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Guarantor House Address" span={2}>
          {loanData?.guarantor_house_address || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Guarantor Office Address" span={2}>
          {loanData?.guarantor_office_address || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Collateral" span={2}>
          {loanData?.collateral?.name || "-"}
        </Descriptions.Item>
      </Descriptions>
    </>
  );

  const renderNumbers = () => (
    <Descriptions title="Loan Numbers" layout="horizontal">
      <Descriptions.Item label="Amount">
        ₦ {Number(loanData?.loan_amount)?.toLocaleString() || "-"}
      </Descriptions.Item>
      <Descriptions.Item label="Interest Rate">
        {Number(loanData?.interest_rate)?.toLocaleString() || "-"} %
      </Descriptions.Item>
      <Descriptions.Item label="Interest Amount">
        ₦{" "}
        {(
          Number(loanData?.net_pay) - Number(loanData?.loan_amount)
        ).toLocaleString()}
      </Descriptions.Item>
      <Descriptions.Item label="Interest Type">
        {loanData?.interest_type}
      </Descriptions.Item>
      <Descriptions.Item label="Net Pay">
        ₦ {Number(loanData?.net_pay)?.toLocaleString() || "-"}
      </Descriptions.Item>
      <Descriptions.Item label="Loan Duration">
        {Number(loanData?.loan_duration)?.toLocaleString() || "-"} Months
      </Descriptions.Item>

      <Descriptions.Item label="Admin Fee">
        ₦ {Number(loanData?.admin_fee)?.toLocaleString() || "-"}
      </Descriptions.Item>
      <Descriptions.Item label="Affidavit Fee">
        ₦ {Number(loanData?.affidavit_fee)?.toLocaleString() || "-"}
      </Descriptions.Item>
      <Descriptions.Item label="Form Fee">
        ₦ {Number(loanData?.form_fee)?.toLocaleString() || "-"}
      </Descriptions.Item>
      <Descriptions.Item label="Insurance Fee">
        ₦ {Number(loanData?.insurance_fee)?.toLocaleString() || "-"}
      </Descriptions.Item>
      <Descriptions.Item label="Processing Fee">
        ₦ {Number(loanData?.processing_fee)?.toLocaleString() || "-"}
      </Descriptions.Item>
    </Descriptions>
  );

  return (
    <div>
      <h6 className="mb-3">
        Client: <strong>{loanData?.client?.fullname || "-"}</strong>
      </h6>

      <Tabs
        tabPosition={isMobile ? "top" : "left"}
        defaultActiveKey={activeView ?? "activeView"}
        className="loan-detail-tabs"
        onChange={(activeKey) => setActiveView(activeKey)}
        activeKey={activeView}
      >
        <TabPane tab="Loan Detail" key="loan_detail">
          {renderLoanDetail()}
        </TabPane>
        <TabPane tab="Loan Numbers" key="loan_numbers">
          {renderNumbers()}
        </TabPane>
        <TabPane tab="Loan Repayment" key="loan_repayment">
          <div>
            <span className="mr-2">
              Total number paid: <strong>{loanData?.paid_days_count}</strong>
            </span>{" "}
            <span className="mr-2">
              Total number unpaid:{" "}
              <strong>{loanData?.unpaid_days_count}</strong>
            </span>{" "}
            <span className="mr-2">
              Total Unpaid Rollover:{" "}
              <strong>{loanData?.unpaid_rollover_count}</strong>
            </span>
            <span>
              Total Cashbacks:{" "}
              <strong>{getTotalCashbackCount(loanData)}</strong>
            </span>
          </div>

          <LoanRepaymentPlan
            loan={loanData}
            onTriggerRefresh={handleTriggerRefresh}
          />
        </TabPane>
        <TabPane tab="Disbursement" key="disbursement">
          {loanData?.disbursed ? (
            <Descriptions title="Disbursed" layout="horizontal">
              <Descriptions.Item label="Disbursement Method">
                {loanData?.payment_method}
              </Descriptions.Item>

              {loanData?.payment_method !== "transfer" ? (
                <Descriptions.Item label="Cheque Number">
                  {loanData?.cheque_number}
                </Descriptions.Item>
              ) : (
                <>
                  <Descriptions.Item label="Bank Name">
                    {loanData?.bank_name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Bank Account Name">
                    {loanData?.bank_account_name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Bank Account Number">
                    {loanData?.bank_account_number}
                  </Descriptions.Item>
                </>
              )}
            </Descriptions>
          ) : (
            <Form form={form} onFinish={handleDisburse}>
              <Row>
                <Col className="" md="6">
                  <Form.Item
                    name="payment_method"
                    label="Payment Method"
                    rules={[
                      {
                        required: true,
                        message: "Please provide payment method.",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select payment method"
                      optionFilterProp="children"
                      className="custom-input"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      onChange={setDisbursementMethod}
                    >
                      <Select.Option value="cheque" key="Cheque">
                        Cheque
                      </Select.Option>
                      <Select.Option value="transfer" key="transfer">
                        Transfer
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                {disbursementMethod === "cheque" && (
                  <Col className="" md="6">
                    <Form.Item
                      name="cheque_number"
                      label="Provide cheque number"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input
                        placeholder="Cheque number"
                        type="text"
                        className="form-control"
                      />
                    </Form.Item>
                  </Col>
                )}

                {disbursementMethod === "transfer" && (
                  <>
                    <Col className="" md="6">
                      <Form.Item
                        name="bank_name"
                        label="Bank Name"
                        rules={[
                          {
                            required: true,
                            message: "Please provide the bank name",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Bank name"
                          type="text"
                          className="form-control"
                        />
                      </Form.Item>
                    </Col>

                    <Col className="" md="6">
                      <Form.Item
                        name="bank_account_name"
                        label="Account Name"
                        rules={[
                          {
                            required: true,
                            message: "Please provide the bank account name",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter Account Name"
                          type="text"
                          className="form-control"
                        />
                      </Form.Item>
                    </Col>

                    <Col className="" md="6">
                      <Form.Item
                        name="bank_account_number"
                        label="Account Number"
                        rules={[
                          {
                            required: true,
                            message: "Please provide the bank account number",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter account number"
                          type="text"
                          className="form-control"
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>

              {!!disbursementMethod && (
                <>
                  <Row>
                    <Col md="6">
                      <Form.Item
                        className="d-flex flex-column text-left"
                        name="repayment_start_date"
                        label="Repayment start date"
                        rules={[
                          {
                            required: true,
                            message: "Please select repayment start date",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Repayment start date"
                          type="date"
                          className="form-control"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <Form.Item
                        className="d-flex align-items-center checkbox-form-item"
                        name="disbursed"
                        label="Click to confirm that client has recieved disbursement"
                        labelAlign="left"
                        valuePropName="checked"
                        rules={[
                          {
                            required: true,
                            message: "Please confirm disbursement",
                          },
                        ]}
                      >
                        <Input
                          style={{ width: "initial" }}
                          type="checkbox"
                          className="form-control-checkbox ml-2"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}

              <Row>
                <Col>
                  <Button
                    disabled={loading}
                    variant="primary"
                    className="px-5"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </TabPane>
      </Tabs>
    </div>
  );
};

export { LoanDetailPage };