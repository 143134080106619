import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { DatePicker, Input, Select, Switch } from "antd";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  useDashboardClientList,
  useFilterDateDropdown,
  useListTitle,
} from "../hooks";
import { useQueryParam, NumberParam } from "use-query-params";
import { POSITIONS_WITH_BRANCHES } from "../utils";
import { LoadingSpinner, ModifiedAntTable } from ".";
import moment from "moment";

import { deleteClientAction } from "../store/clients";
import { assignListClientsToCoAction } from "../store/lists";

const filters = [
  {
    label: "Active",
    value: "ACTIVE",
  },
  {
    label: "Registered",
    value: "REGISTERED",
  },
  {
    label: "All",
    value: "",
  },
  {
    label: "Has Completed all loans",
    value: "COMPLETED LOANS",
  },
];

const viewTypeOptions = {
  ALL_CLIENTS: true,
  MY_CLIENTS: false,
};

const DashboardClientList = () => {
  const {
    id,
    listData,
    handleListRefreshTrigger,
    loading,
    filterDate,
    staffs,
    setFilterDate,
    filterName,
    setFilterName,
    filterStatus,
    setFilterStatus,
    shouldShowViewType,
  } = useDashboardClientList();
  const { pathname } = useLocation();
  const { user } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const [selectedRowKeysState, setSelectedRowKeysState] = useState([]);
  const [selectAction, setSelectAction] = useState();
  const [filteredData, setFilteredData] = useState(listData);

  const [page, setPage] = useQueryParam("page", NumberParam);
  const [viewType, setViewType] = useState(
    user?.position === "BDO"
      ? viewTypeOptions.MY_CLIENTS
      : viewTypeOptions.ALL_CLIENTS
  );
  const { renderFilter } = useFilterDateDropdown({ setFilterDate });
  const { fullListName } = useListTitle(
    "Clients",
    user?.position === "BDO" || pathname?.includes("staff")
  );

  const handleFilter = () => {
    setFilteredData(() =>
      listData?.filter(
        (val) =>
          `${val?.surname} ${val?.firstname}`
            ?.toLowerCase()
            ?.includes(filterName?.toLowerCase()) &&
          (val.client_status === filterStatus ||
            filterStatus === "" ||
            !filterStatus) &&
          (viewType !== viewTypeOptions.ALL_CLIENTS
            ? String(val?.handler_id) === String(user?.id)
            : true)
      )
    );
  };

  const handleDeleteLoan = async (id) => {
    if (window.confirm("Are you sure you want to delete this client?.")) {
      await dispatch(deleteClientAction(id));
      handleListRefreshTrigger();
    }
  };

  // Handle Filter
  useEffect(() => {
    handleFilter();
  }, [filterStatus, listData, viewType]);

  const handleReAssign = async (CoId) => {
    const ids = selectedRowKeysState?.join(",");
    await dispatch(
      assignListClientsToCoAction({
        clientsToAssign: ids,
        newHandlerId: CoId,
        oldCoId: id,
      })
    );

    handleListRefreshTrigger();
  };

  const getClientColumns = useCallback(() => {
    const columns = [
      {
        title: "S/N",
        dataIndex: "id",
        render: (text, record, index) =>
          ((Number(page) || 1) - 1) * 50 + (index + 1),
        summary: ["-", "Summations"],
      },
      {
        title: "Name",
        dataIndex: "name",
        render: (text, record) => (
          <Link to={`/clients/${record.id}`}>
            <i className="fa fa-link" aria-hidden="true"></i>{" "}
            {`${record?.surname} ${record?.firstname}`}
          </Link>
        ),
      },
      {
        title: "Handler",
        dataIndex: "handler_fullname",
      },
      {
        title: "status",
        render: (text, record) => (
          <div className="d-flex justify-content-center">
            <div className="status-badge badge badge-sm">
              {record?.client_status}
            </div>
          </div>
        ),
      },
      {
        title: "Reg Date",
        render: (text, record) => (
          <>{moment(record?.reg_date).format("YYYY-MM-DD")}</>
        ),
      },
      {
        title: "Loan Date",
        render: (text, record) => (
          <>
            {record?.last_loan_date
              ? moment(record?.last_loan_date).format("YYYY-MM-DD")
              : "-"}
          </>
        ),
      },
    ];

    if (!POSITIONS_WITH_BRANCHES.includes(user?.position)) {
      columns.push({
        title: "",
        dataIndex: "id",
        render: (text, record) => (
          <span role="button" onClick={() => handleDeleteLoan(record?.id)}>
            <i className="fas fa-trash"></i>
          </span>
        ),
        summary: "-",
      });
    }

    return columns;
  }, [user]);

  return (
    <Container fluid>
      <div className="d-flex justify-content-between list-title-with-switch">
        <h3 className="page-title mb-4">{fullListName}</h3>

        {shouldShowViewType && (
          <Switch
            checked={viewType}
            checkedChildren="All clients"
            unCheckedChildren="My clients"
            onChange={setViewType}
            className="view-switch"
          />
        )}
      </div>

      <Input.Group compact className="mb-3 filter-panel">
        <Select
          className="default-ant-select"
          style={{ width: "50%" }}
          placeholder="Filter by status"
          value={filterStatus}
          onChange={(val) => setFilterStatus(val)}
        >
          {filters?.map(({ label, value }, index) => (
            <Select.Option key={index} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
        <Input
          style={{ width: "50%" }}
          placeholder="Filter by name"
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
        />
      </Input.Group>

      <div className="mb-2">
        <strong>
          Filter by{" "}
          {filterStatus === "ACTIVE"
            ? "Last loan request date: "
            : "Registration date: "}
        </strong>
      </div>
      <Row compact className="mb-3 filter-panel">
        <Col className="d-flex">
          {renderFilter()}
          <DatePicker.RangePicker
            allowClear
            style={{ width: "100%" }}
            onChange={(val) => setFilterDate(val ?? [])}
            className="mb-3"
            value={filterDate}
          />
        </Col>
      </Row>

      {!!selectedRowKeysState?.length && user?.position !== "BDO" && (
        <Row>
          <Col md="4">
            <Select
              placeholder="Choose action"
              className="default-ant-select mb-3"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => setSelectAction(value)}
            >
              <Select.Option value={"newCo"} key={"newCo"}>
                Assign to new handler
              </Select.Option>
            </Select>
          </Col>

          {selectAction === "newCo" && (
            <Col md="4" className="align-items-center">
              <Select
                placeholder="Choose action"
                className="default-ant-select mb-3"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(value) => handleReAssign(value)}
                value={null}
                showSearch
              >
                {staffs?.map((val, index) => (
                  <Select.Option value={val?.id} key={val?.id} id={val?.id}>
                    {val?.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          )}
        </Row>
      )}

      <LoadingSpinner loading={loading} />

      <ModifiedAntTable
        rowSelection={{
          type: "checkbox",
          selectedRowKeys: selectedRowKeysState,
          onChange: (selectedRowKeys) =>
            setSelectedRowKeysState(selectedRowKeys),
        }}
        rowKey="id"
        scroll={{ x: "max-content" }}
        columns={getClientColumns()}
        dataSource={filteredData}
        bordered
        showSummary={false}
        pagination={{
          defaultCurrent: page,
          current: page,
          defaultPageSize: 50,
          hideOnSinglePage: true,
          onChange: (current) => setPage(current),
        }}
      />
    </Container>
  );
};

export { DashboardClientList };
