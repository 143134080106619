import { DatePicker, Descriptions, Drawer, Input } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useQueryParam, NumberParam } from "use-query-params";

import {
  getAllCreateCollateralsAction,
  collateralRUDAction,
} from "../store/clients";

import { useFilterDateDropdown, useListTitle } from "../hooks";
import { getIdFieldAndId, getSummationOfFields } from "../utils";
import { ImageUpload, ModifiedAntTable } from ".";
import { Col, Row } from "react-bootstrap";

const CollateralList = () => {
  const [filterDate, setFilterDate] = useState([
    moment().subtract(60, "days"),
    moment(),
  ]);
  const [summedData, setSummedData] = useState({});
  const [filteredCollaterals, setFilteredCollaterals] = useState([]);
  const [filterName, setfilterName] = useState("");
  const [activeCollateral, setActiveCollateral] = useState();
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [page, setPage] = useQueryParam("page", NumberParam);
  const dispatch = useDispatch();
  const { orgType, orgId } = useParams();
  const {
    users: { user },
    clients: { collaterals, loading },
  } = useSelector((state) => ({
    users: state.users,
    clients: state.clients,
  }));
  const { org_id_field, org_id } = getIdFieldAndId(orgType, orgId);

  const { renderFilter } = useFilterDateDropdown({
    setFilterDate,
    menuClassName: "mt-0",
  });
  const { fullListName } = useListTitle("collaterals");

  useEffect(() => {
    console.log({ collaterals });
    setFilteredCollaterals(() =>
      collaterals?.[org_id_field]?.[org_id]?.filter((instance) =>
        (instance.name ?? "")
          .toLowerCase()
          .includes(filterName?.toLocaleLowerCase())
      )
    );
  }, [filterName]);

  useEffect(() => {
    setFilteredCollaterals(collaterals?.[org_id_field]?.[org_id]);
  }, [collaterals, orgType, orgId]);

  useEffect(() => {
    setSummedData(
      getSummationOfFields({
        data: filteredCollaterals,
        fieldsToSum: ["amount"],
      })
    );
  }, [filteredCollaterals]);

  useEffect(() => {
    const queryString = `start_date=${filterDate[0].format(
      "YYYY/MM/DD"
    )}&end_date=${filterDate[1].format(
      "YYYY/MM/DD"
    )}&${org_id_field}=${org_id}`;

    dispatch(
      getAllCreateCollateralsAction({
        querySet: queryString,
        orgId: org_id,
        orgType: org_id_field,
      })
    );
  }, [filterDate, refreshTrigger]);

  useEffect(() => {
    setActiveCollateral();
  }, [refreshTrigger]);

  const handleUpload = (url) => {
    dispatch(
      collateralRUDAction(activeCollateral?.id, "PATCH", { image: url })
    ).then(() => {
      setRefreshTrigger((current) => !current);
    });
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this from record?.")) {
      await dispatch(collateralRUDAction(id, "DELETE"));
      setRefreshTrigger((current) => !current);
    }
  };

  const tableColumn = [
    {
      title: "S/N",
      dataIndex: "id",
      render: (text, record, index) =>
        ((Number(page) || 1) - 1) * 50 + (index + 1),
      summary: "-",
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (text, record) =>
        record?.image ? (
          <img width={30} height={30} src={record?.image} />
        ) : (
          "-"
        ),
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Client",
      dataIndex: "client_name",
      render: (text, record, index) => (
        <Link to={`/clients/${record?.client?.id}`}>
          <i className="fa fa-link" aria-hidden="true"></i>{" "}
          {`${record?.client?.fullname}`}
        </Link>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Amount (₦)",
      dataIndex: "amount",
      render: (text, record, index) => (
        <div>{Number(record?.amount).toLocaleString()}</div>
      ),
      summary: summedData["amount"],
    },
    {
      title: "Associated Loan",
      dataIndex: "loan",
      render: (text, record, index) =>
        record?.loan_id && record?.client_name ? (
          <Link to={`/loans/${record?.loan_id}`}>
            <i className="fa fa-link" aria-hidden="true"></i>{" "}
            {`${record?.client_name}`}
          </Link>
        ) : (
          <div>None</div>
        ),
    },
  ];

  if (["DR", "SM", "ZM"].includes(user?.position)) {
    tableColumn.push({
      title: "",
      dataIndex: "id",
      render: (text, record) => (
        <span role="button" onClick={() => handleDelete(record?.id)}>
          <i className="fas fa-trash"></i>
        </span>
      ),
      summary: "-",
    });
  }

  return (
    <div>
      <h3 className="page-title mb-4">{fullListName}</h3>
      <Input.Group compact className="mb-3 filter-panel d-flex flex-row">
        <Input
          style={{ width: "100%" }}
          placeholder="Filter by name"
          value={filterName}
          onChange={(e) => setfilterName(e.target.value)}
        />
      </Input.Group>

      <Row>
        <Col className="d-flex">
          {renderFilter()}
          <DatePicker.RangePicker
            style={{ width: "100%" }}
            onChange={(val) => setFilterDate(val ?? [])}
            className="mb-3"
            value={filterDate}
          />
        </Col>
      </Row>

      <ModifiedAntTable
        loading={loading}
        scroll={{ x: "max-content" }}
        columns={tableColumn}
        dataSource={filteredCollaterals}
        bordered
        showSummary={true}
        pagination={{
          defaultCurrent: page,
          current: page,
          defaultPageSize: 50,
          hideOnSinglePage: true,
          onChange: (current) => setPage(current),
        }}
        onRow={(record, rowIndex) => ({
          onClick: () => setActiveCollateral(record),
        })}
      />

      <Drawer
        title={activeCollateral?.name}
        placement="bottom"
        onClose={() => setActiveCollateral()}
        visible={!!activeCollateral}
        size="large"
      >
        <ImageUpload
          uploadDir="collaterals"
          image={activeCollateral?.image}
          onImageUpload={(url) => handleUpload(url)}
          onDeleteImage={() => handleUpload(null)}
          className="collateral_img mb-2"
        />

        <Descriptions>
          <Descriptions.Item label="Name">
            {activeCollateral?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Date">
            {activeCollateral?.date}
          </Descriptions.Item>
          <Descriptions.Item label="Estimated Amount">
            ₦ {Number(activeCollateral?.amount)?.toLocaleString()}
          </Descriptions.Item>
        </Descriptions>
      </Drawer>
    </div>
  );
};

export { CollateralList };
