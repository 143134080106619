import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getStaffsWithParams } from "../store/clients";
import { getIdFieldAndId } from "../utils";

const useDashboardStaffList = () => {
  const {
    users: { user },
    clients: { staffs },
  } = useSelector((state) => ({
    users: state.users,
    clients: state.clients,
  }));
  const dispatch = useDispatch();
  const { orgType, orgId } = useParams();
  const [listRefreshTrigger, setListRefreshTrigger] = useState(false);
  const [loading, setLoading] = useState(false);
  // useful for loan request form
  const { org_id_field, org_id } = getIdFieldAndId(orgType, orgId)

  const handleListRefreshTrigger = () =>
    setListRefreshTrigger((current) => !current);

  useEffect(async () => {
    let queryString = `${org_id_field}=${org_id}`;
    setLoading(true);
    await dispatch(getStaffsWithParams(queryString, "", org_id_field, org_id));

    setLoading(false);
  }, [listRefreshTrigger, orgType]);

  return {
    listData: staffs?.[org_id_field]?.[org_id],
    id: org_id,
    handleListRefreshTrigger,
    loading,
  };
};

export { useDashboardStaffList };
