import { DatePicker, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useQueryParam, NumberParam } from "use-query-params";
import { Tabs } from "antd";
import { useParams } from "react-router";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { ModifiedAntTable } from ".";
import { useFilterDateDropdown, useListTitle } from "../hooks";
import { parse } from "query-string";
import { getIdFieldAndId, getSummationOfFields } from "../utils";

import { confirmBatchPayment } from "../store/loans";
import { showAlertAction } from "../store/alert";
import { getMonthlyPayWithParams } from "../store/loans";

const { TabPane } = Tabs;

const DashboardMonthlypayList = () => {
  // Defaults to 3 days from now
  const [dateRange, setDateRage] = useState([
    moment().subtract(0, "days"),
    moment(),
  ]);
  const {
    users: { user },
    loans: { loadingMonthlyPay, monthlyPay },
  } = useSelector((state) => ({
    users: state.users,
    loans: state.loans,
  }));
  const { orgType, orgId } = useParams();
  const { status: routeStatus } = parse(window.location.search);
  const { fullListName } = useListTitle("monthly payments");
  const dispatch = useDispatch();
  const [page, setPage] = useQueryParam("page", NumberParam);
  const [filteredMonthlyPay, setFilteredMonthlyPay] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [activeTab, setActiveTab] = useState("installments");
  const [summedData, setSummedData] = useState({});
  const [status, setStatus] = useState(routeStatus);
  const [rolloverStatus, setRolloverStatus] = useState();
  const [selectedRowKeysState, setSelectedRowKeysState] = useState("");
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const { org_id_field, org_id } = getIdFieldAndId(orgType, orgId);

  const { renderFilter } = useFilterDateDropdown({
    setFilterDate: setDateRage,
  });

  useEffect(() => {
    setSummedData(
      getSummationOfFields({
        data: filteredMonthlyPay,
        fieldsToSum: ["amount"],
        fieldWithHandlers: {
          total: (rowInstance) => getTotal(rowInstance),
          cashInHand: (rowInstance) => getCashInHand(rowInstance),
        },
      })
    );
  }, [activeTab, filteredMonthlyPay]);

  const getData = async () => {
    let queryString = "";

    queryString = queryString.concat(
      `${org_id_field}=${org_id}&start_date=${dateRange?.[0]?.format(
        "YYYY/MM/DD"
      )}&end_date=${dateRange?.[1]?.format("YYYY/MM/DD")}`
    );
    await dispatch(getMonthlyPayWithParams(queryString, org_id, org_id_field));
  };

  const handleApproveLoans = async () => {
    if (user?.position !== "HOA" && user?.position !== "BHOA") {
      dispatch(
        showAlertAction({
          message: "This action can only be carried out by the accountant",
          level: "danger",
          isVisible: true,
        })
      );
      return;
    }

    if (
      window.confirm("Are you sure you want to approve selected payments?.")
    ) {
      dispatch(confirmBatchPayment({ ids: selectedRowKeysState })).then(() => {
        setTriggerRefresh((current) => !current);
      });
    }
  };

  const renderStatus = (status) => (
    <div
      className={classNames(
        "status-badge badge badge-sm flex justify-center",
        status === "Paid" ? "badge-success" : status === "Default" ? "badge-danger" : "badge-warning"
      )}
    >
      {status}
    </div>
  );

  useEffect(() => {
    setFilteredMonthlyPay(() =>
      monthlyPay?.[org_id_field]?.[org_id]?.filter(
        (val) =>
          val?.client_name
            ?.toLowerCase()
            ?.includes(filterName?.toLowerCase()) &&
          (val?.rollover_status === rolloverStatus ||
            rolloverStatus === "" ||
              !rolloverStatus) &&
          (val?.status === status ||
            !status ||
            (status === "unpaid_rollover" &&
              !!val.has_rollover &&
              !(val.rollover_status === 'Paid')))
      )
    );
  }, [filterName, status, monthlyPay, rolloverStatus]);

  useEffect(() => {
    getData();
  }, [dateRange, orgType, triggerRefresh]);

  const tableColumns = [
    {
      title: "S/N",
      dataIndex: "id",
      render: (text, record, index) =>
        ((Number(page) || 1) - 1) * 50 + (index + 1),
      summary: ["", "Summations"],
    },
    {
      title: "Date",
      render: (text, record) => <>{record?.date}</>,
      summary: "-",
    },
    {
      title: "Amount ₦",
      dataIndex: "amount",
      render: (text, record) => (
        <>{Number(record?.amount || 0).toLocaleString()}</>
      ),
      summary: summedData["amount"],
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => renderStatus(record?.status),
      summary: "-",
    },
    {
      title: "Rollover Status",
      dataIndex: "rollover_status",
      render: (text, record) => renderStatus(record?.rollover_status),
      summary: "-",
    },
    {
      title: "Loan",
      dataIndex: "loan",
      render: (text, record) => (
        <Link to={`/loans/${record.loan_id}`}>
          <i className="fa fa-link" aria-hidden="true"></i> By:{" "}
          {`${record?.client_name}`}
        </Link>
      ),
      summary: "-",
    },
    {
      title: "Handler",
      dataIndex: "handler_name",
      summary: "-",
    },
  ];

  const renderTableData = (tab) => {
    return (
      <Row>
        <Col>
          {!!selectedRowKeysState?.length && (
            <Row>
              <Col md="4">
                <button
                  className="btn btn-sm btn-primary mb-2"
                  onClick={() => handleApproveLoans()}
                >
                  Approve Month Pay
                </button>
              </Col>
            </Row>
          )}

          <ModifiedAntTable
            rowKey="id"
            scroll={{ x: "max-content" }}
            columns={tableColumns}
            dataSource={filteredMonthlyPay}
            bordered
            rowSelection={{
              type: "checkbox",
              selectedRowKeys: selectedRowKeysState,
              onChange: (selectedRowKeys) =>
                setSelectedRowKeysState(selectedRowKeys),
            }}
            pagination={{
              defaultCurrent: page,
              current: page,
              defaultPageSize: 50,
              hideOnSinglePage: true,
              onChange: (current) => setPage(current),
            }}
            showSummary={true}
          />
        </Col>
      </Row>
    );
  };

  return (
    <Container fluid className="daily-pay-list">
      <h3 className="page-title mb-4">{fullListName}</h3>

      <Input.Group compact className="mb-3 filter-panel">
        <Select
          allowClear
          className="default-ant-select"
          style={{ width: "30%" }}
          placeholder="Filter by status"
          value={status}
          onChange={setStatus}
        >
          <Select.Option key="Paid" value="Paid">
            Paid
          </Select.Option>
          <Select.Option key="Unpaid" value="Unpaid">
            Unpaid
          </Select.Option>
          <Select.Option key="Default" value="Default">
            Default
          </Select.Option>
          <Select.Option key="Partial" value="Partial">
            Partial Payment
          </Select.Option>
          <Select.Option key="unpaid_rollover" value="unpaid_rollover">
            Has unpaid rollover
          </Select.Option>
        </Select>
        <Select
          allowClear
          className="default-ant-select"
          style={{ width: "30%" }}
          placeholder="Filter by rollover status"
          value={rolloverStatus}
          onChange={setRolloverStatus}
        >
          <Select.Option key="Paid" value="Paid">
            Paid
          </Select.Option>
          <Select.Option key="Unpaid" value="Unpaid">
            Unpaid
          </Select.Option>
          <Select.Option key="Partial" value="Partial">
            Partial
          </Select.Option>
          <Select.Option key="Waived" value="Waived">
            Waived
          </Select.Option>
        </Select>
        <Input
          style={{ width: "40%" }}
          placeholder="Filter by client's firstname or surname"
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
        />
      </Input.Group>

      <Row>
        <Col className="d-flex">
          {renderFilter()}
          <DatePicker.RangePicker
            allowClear={false}
            style={{ width: "100%" }}
            value={dateRange}
            onChange={setDateRage}
            className="mb-3"
          />
        </Col>
      </Row>
      {loadingMonthlyPay && (
        <div className="d-flex w-100 justify-content-center align-items-center my-2">
          <i class="fas fa-spinner fa-spin "></i>{" "}
          <span className="ml-2">Loading...</span>
        </div>
      )}
      <Tabs
        defaultActiveKey="1"
        onChange={(active) => setActiveTab(active)}
        className=""
      >
        <TabPane tab="" key="installments">
          {renderTableData("installments")}
        </TabPane>
      </Tabs>
    </Container>
  );
};

export { DashboardMonthlypayList };
