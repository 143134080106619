import { Descriptions, Drawer } from "antd";
import React from "react";
import { getTotal } from "../utils";

const PaymentDistributionDrawer = ({ activeRecord, setActiveRecord }) => {
  const isMobile = window.innerWidth < 768
  return (
    <Drawer
      title={activeRecord?.date}
      placement="bottom"
      onClose={() => setActiveRecord()}
      visible={!!activeRecord}
      size={isMobile ? "small" : "large"}
    >
      <Descriptions title="Totals">
        <Descriptions.Item label="Total collection">
          ₦ {getTotal(activeRecord)}
        </Descriptions.Item>
        <Descriptions.Item label="Default">
          ₦ {activeRecord?.default_amount}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title="Realisable">
        <Descriptions.Item label="Installment">
          ₦ {Number(activeRecord?.realizable_installment || 0).toLocaleString()}
        </Descriptions.Item>

        <Descriptions.Item label="Interest">
          ₦ {Number(activeRecord?.realizable_insterest || 0).toLocaleString()}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title="Realised">
        <Descriptions.Item label="Installment">
          ₦ {Number(activeRecord?.realised_installment || 0).toLocaleString()}
        </Descriptions.Item>

        <Descriptions.Item label="Interest">
          ₦ {Number(activeRecord?.realised_insterest || 0).toLocaleString()}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title="Generals">
        <Descriptions.Item label="Org Expenses">
          ₦ {Number(activeRecord?.org_expenses || 0).toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item label="Special Income">
          ₦ {Number(activeRecord?.special_income || 0).toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item label="Asset auction amount">
          ₦ {Number(activeRecord?.sold_assets || 0).toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item label="Asset retrieval amount">
          ₦ {Number(activeRecord?.retrieved_assets || 0).toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item label="Waiver Balance">
          ₦ {Number(activeRecord?.total_waiver_balance || 0).toLocaleString()}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title="Fees">
        <Descriptions.Item label="Admin Fee">
          ₦ {Number(activeRecord?.total_admin_fee || 0).toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item label="Form Fee">
          ₦ {Number(activeRecord?.total_form_fee || 0).toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item label="Affidavit Fee">
          ₦ {Number(activeRecord?.total_affidavit_fee || 0).toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item label="Insurance Fee">
          ₦ {Number(activeRecord?.total_insurance_fee || 0).toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item label="Processing Fee">
          ₦ {Number(activeRecord?.total_processing_fee || 0).toLocaleString()}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title="Other Payments">
        <Descriptions.Item label="Defaults Recovered">
          ₦ {Number(activeRecord?.prev_cash_in_hand_paid || 0).toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item label="Advance">
          ₦ {Number(activeRecord?.advanced_payment || 0).toLocaleString()}
        </Descriptions.Item>
      </Descriptions>
    </Drawer>
  );
};

export { PaymentDistributionDrawer };
