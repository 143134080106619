import React, { useEffect, useState } from "react";
import { notification } from "antd";
import { Link, useHistory } from "react-router-dom";
import { Navbar, Container, Nav, Button } from "react-bootstrap";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import { handleLogoutAction } from "../store/users";
import { handleGettAllMessages, readLastNotification } from "../store/messages";

const AdminNavbar = () => {
  const [notificationRenderCount, setNotificationRenderCount] = useState(0);
  const {
    users: { user },
    messages: { messages },
  } = useSelector((state) => ({
    users: state.users,
    messages: state.messages,
  }));
  const history = useHistory();
  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };
  const dispatch = useDispatch();

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(handleLogoutAction()).then(() => history.push("/login/"));
  };

  //Get user Notification. This is done here since this page would not re-render
  const openNotification = (message) => {
    notification.open({
      message: `From ${message?.sender?.name}`,
      duration: null,
      description: message?.content,
      onClick: () => {
        history.push("/messages");
      },
      onClose: () => {
        dispatch(readLastNotification());
      },
    });
  };

  useEffect(() => {
    const userString = `user_id=${user?.id}`;
    dispatch(handleGettAllMessages(userString));
  }, []);

  useEffect(() => {
    if (notificationRenderCount > 1) {
      // Based this would prevent multiple notification when we visit the message page
      return;
    }
    const lastNotification = messages.find((val) => val?.sender.id !== user.id);
    if (messages?.length && lastNotification) {
      const lastNotificationTime = moment(lastNotification?.sent_at);
      const lastUserReadTime = moment(user?.last_notifications_view_time);
      if (lastNotificationTime > lastUserReadTime) {
        openNotification(lastNotification);
      }
    }
    setNotificationRenderCount((current) => current + 1);
  }, [messages]);

  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
          <Button
            variant="dark"
            className="d-lg-none d-flex justify-content-center align-items-center rounded-circle p-2"
            onClick={mobileSidebarToggle}
          >
            <i className="fas fa-ellipsis-v"></i>
          </Button>
        </div>

        <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
        </Navbar.Toggle>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto" navbar>
            <Nav.Link className="d-flex align-items-center">
              <Link className="m-0" to={`/staffs/profile/${user?.id}`}>
                <strong className="no-icon nav-link">Account</strong>
              </Link>
            </Nav.Link>
            {(user.position === "DR" || user.is_superuser) && (
              <Nav.Link
                className="d-flex align-items-center"
                target="_blank"
                rel="no-referrer"
                href="https://api.m.opulentpeerless.com/admin/"
              >
                <strong className="no-icon nav-link">Admin</strong>
              </Nav.Link>
            )}
            <Nav.Item className="d-flex align-items-center">
              <Nav.Link className="m-0 p-0" onClick={handleLogout}>
                <strong className="no-icon">Log out</strong>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export { AdminNavbar };
