import { Drawer, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { messagingPositionMapping, BRANCH_LEVEL_POSITIONS } from "../utils";

import { getStaffsWithParams } from "../store/clients";
import { sendMessagesAction } from "../store/messages";
import { getAllBranches } from "../store/org";

const NewMessageForm = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    users: { user },
    org: { branches },
  } = useSelector((state) => ({
    users: state.users,
    org: state.org
  }));
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [destinationType, setDestinationType] = useState("");
  const [staffs, setStaffs] = useState([]);
  const isMobile = window.innerWidth <= 768;

  const getRelatedOrgSelectField = async () => {
    setLoading(true);

    if (BRANCH_LEVEL_POSITIONS.includes(user.position)) {
      const querySting = `branch_id=${user?.branch?.id}`
      if (destinationType === "STAFF") {
        let res = await dispatch(getStaffsWithParams("", querySting));
        if (res) {
          res = res.filter((staffData) => staffData?.id !== user.id);
          setStaffs(res)
        }
      } else {
        await dispatch(getAllBranches("", querySting));
      }
      return
    }

    if (destinationType === "STAFF") {
      let res = await dispatch(getStaffsWithParams("", ""));
      if (res) {
        res = res.filter((staffData) => staffData?.id !== user.id);
        setStaffs(res)
      }
    } else {
      await dispatch(getAllBranches());
    }
    setLoading(false);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleSubmit = async (values) => {
    setLoading(true);

    await dispatch(
      sendMessagesAction({
        ...values,
        content: values.content,
        sender_id: user?.id,
      })
    );
    setLoading(false);
    form.resetFields();
    setVisible(false);
  };

  // useEffect(() => {
  //   // if (destinationType === "STAFF") {
  //   //   const destination = getOrgStaffProfileOrgField(user?.position);
  //   setUserOrgFieldMeta(
  //     getOrgFieldMeta(user, destination?.toLocaleLowerCase())
  //   );
  //   //   return;
  //   // }
  //   // setUserOrgFieldMeta(
  //   //   getOrgFieldMeta(user, destinationType?.toLocaleLowerCase())
  //   // );
  // }, [destinationType]);

  useEffect(() => {
    if (!destinationType || destinationType === "ALL ORG") {
      return;
    }
    getRelatedOrgSelectField();
  }, [destinationType]);

  return (
    <>
      {user?.position !== "BDO" && <div className="button-wrapper">
        <Button
          className="new-message-button"
          onClick={() => setVisible((current) => !current)}
        >
          Send Message <i className="fas fa-solid fa-plus"></i>
        </Button>
      </div>}

      <Drawer
        title="Send Message"
        placement="bottom"
        onClose={onClose}
        visible={visible}
        size={isMobile ? "default" : "large"}
      >
        <Form form={form} onFinish={handleSubmit}>
          <Row>
            <Col md="6">
              <Form.Item
                name="position"
                label="Select where your message should be broadcasted"
                rules={[
                  {
                    required: true,
                    message: "Please select an option",
                  },
                ]}
              >
                <Select
                  placeholder="Select message type"
                  optionFilterProp="children"
                  className="custom-input"
                  onChange={setDestinationType}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                >
                  {messagingPositionMapping?.[user.position].map((val) => (
                    <Select.Option value={val} key={val}>
                      {val}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {destinationType === "BRANCH" && (
              <Col md="6">
                <Form.Item
                  name="branch_id"
                  rules={[
                    {
                      required: true,
                      message: "Please an an option",
                    },
                  ]}
                  label="Select a Branch"
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    className="custom-input"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                    }
                    allowClear
                  >
                    {branches?.map(({ id, name }) => (
                      <Select.Option value={id} key={id}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}

            {destinationType === "STAFF" && (
              <Col md="6">
                <Form.Item
                  name="user_id"
                  rules={[
                    {
                      required: true,
                      message: "Please an an option",
                    },
                  ]}
                  label="Select a Staff"
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    className="custom-input"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                    }
                    allowClear
                  >
                    {staffs?.map(({ id, name }) => (
                      <Select.Option value={id} key={id}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row>
            <Col md="6">
              <Form.Item
                name="content"
                label="Message"
                rules={[
                  {
                    required: true,
                    message: "Please enter message",
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <Button
                variant="primary"
                className="px-5"
                disabled={loading}
                type="submit"
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export { NewMessageForm };
