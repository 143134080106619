import {
  ViewHolidays,
  CreateHolidays,
  DashboardStaffList,
  RegisterGuarantor,
  LoanDetailPage,
  DashboardLoanList,
  DashboardClientList,
  ExternaInvestmentList,
  ExternaInvestmentForm,
  RefundToExternalInvestmentList,
  RefundToExternalInvestmentForm,
  BranchesList,
  CollateralList,
  CollateralForm,
  OrgExpenseList,
  OrgExpenseForm,
  ReportsList,
  ReportsForm,
  ProcurementsList,
  ProcurementsForm,
  SeizedAssetDetail,
  SeizedAssetsList,
  SeizedAssetsForm,
  SpecialIncomeList,
  DashboardMonthlypayList,
  ViewMessages,
  RegisterClient,
  LoanRequestForm,
  StaffProfile,
  StaffCreateForm,
  Dashboard,
  CreateOrgLevel,
  ClientProfile,
  SpecialIncomeForm
} from "../components"

const useRoutes = () => {
  const dashboardRoutes = [
    {
      path: "/",
      name: "Dashboard",
      icon: "nc-icon nc-chart-pie-35",
      component: Dashboard,
      showOnSidebar: true,
      permissionLevel: 1,
    },
    {
      name: "General",
      icon: "nc-icon nc-air-baloon",
      showOnSidebar: true,
      permissionLevel: 1,
      subLayouts: [
        {
          path: "/org_expenses/new",
          name: "Add Org Expense",
          icon: "nc-icon nc-air-baloon",
          component: OrgExpenseForm,
          showOnSidebar: true,
          permissionLevel: 2,
        },
        {
          path: "/specialincome/new",
          name: "Special Income",
          icon: "nc-icon nc-air-baloon",
          component: SpecialIncomeForm,
          showOnSidebar: true,
          permissionLevel: 2,
        },
        {
          path: "/seizedasset/new",
          name: "Add seized assets",
          icon: "nc-icon nc-air-baloon",
          component: SeizedAssetsForm,
          showOnSidebar: true,
          permissionLevel: 1,
        },
        {
          path: "/external_investment/new",
          name: "External Investment",
          icon: "nc-icon nc-air-baloon",
          component: ExternaInvestmentForm,
          showOnSidebar: true,
          permissionLevel: 2,
        },
        {
          path: "/external_investments",
          name: "External Investments list",
          icon: "nc-icon nc-air-baloon",
          component: ExternaInvestmentList,
          showOnSidebar: false,
          permissionLevel: 2,
        },
        {
          path: "/refund_external_investment/new",
          name: "Refund Ex-Investors",
          icon: "nc-icon nc-air-baloon",
          component: RefundToExternalInvestmentForm,
          showOnSidebar: true,
          permissionLevel: 2,
        },
        {
          path: "/refund_external_investments",
          name: "Refund to Ex-Investors list",
          icon: "nc-icon nc-air-baloon",
          component: RefundToExternalInvestmentList,
          showOnSidebar: false,
          permissionLevel: 1,
        },
        {
          path: "/procurements/new",
          name: "Add Procurement",
          icon: "nc-icon nc-air-baloon",
          component: ProcurementsForm,
          showOnSidebar: true,
          permissionLevel: 2,
        },
        {
          path: "/reports/new",
          name: "Add Report",
          icon: "nc-icon nc-air-baloon",
          component: ReportsForm,
          showOnSidebar: true,
          permissionLevel: 0,
        },
      ],
    },
    {
      path: "/messages",
      name: "Messages",
      icon: "nc-icon nc-chat-round",
      component: ViewMessages,
      showOnSidebar: true,
      permissionLevel: 0,
    },
    {
      name: "Customers",
      icon: "nc-icon nc-circle-09",
      showOnSidebar: true,
      permissionLevel: 1,
      subLayouts: [
        {
          path: "/client-register/",
          name: "Register Clients",
          icon: "nc-icon nc-chart-pie-35",
          component: RegisterClient,
          showOnSidebar: true,
          permissionLevel: 1,
        },
        {
          path: "/guarantor-register/",
          name: "Register Guarantor",
          icon: "nc-icon nc-badge",
          component: RegisterGuarantor,
          showOnSidebar: true,
          permissionLevel: 1,
        },
        {
          path: "/clients/:id",
          name: "Client Profile",
          icon: "nc-icon nc-chart-pie-35",
          component: ClientProfile,
          showOnSidebar: false,
          permissionLevel: 1,
        },
        {
          path: "/clients/",
          name: "Clients",
          icon: "nc-icon nc-chart-pie-35",
          component: DashboardClientList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/:orgType/:orgId/clients/",
          name: "Org Dashboard Edit",
          icon: "nc-icon nc-chart-pie-35",
          component: DashboardClientList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
      ],
    },
    {
      name: "Loans",
      icon: "nc-icon nc-money-coins",
      showOnSidebar: true,
      permissionLevel: 1,
      subLayouts: [
        {
          path: "/new-loan/",
          name: "Request Loan",
          icon: "nc-icon nc-money-coins",
          component: LoanRequestForm,
          showOnSidebar: true,
          permissionLevel: 1,
        },
        {
          path: "/collaterals/new",
          name: "Add Collateral",
          icon: "nc-icon nc-air-baloon",
          component: CollateralForm,
          showOnSidebar: true,
          permissionLevel: 0,
        },
        {
          path: ["/:orgType/:orgId/collaterals/", "/collaterals/"],
          name: "Collaterals",
          icon: "nc-icon nc-air-baloon",
          component: CollateralList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: ["/loans/", "/:orgType/:orgId/loans/"],
          name: "Loan Detail",
          icon: "nc-icon nc-chart-pie-35",
          component: DashboardLoanList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/loans/:id/",
          name: "Loan Detail",
          icon: "nc-icon nc-chart-pie-35",
          component: LoanDetailPage,
          showOnSidebar: false,
          permissionLevel: 0,
        },
      ],
    },
    {
      name: "Staffs",
      icon: "nc-icon nc-bell-55",
      showOnSidebar: true,
      permissionLevel: 2,
      subLayouts: [
        {
          path: "/staffs-register/",
          name: "Create Staff",
          icon: "nc-icon nc-chart-pie-35",
          component: StaffCreateForm,
          showOnSidebar: true,
          permissionLevel: 2,
        },
        {
          path: "/staffs/profile/:id",
          name: "Staff Profile",
          icon: "nc-icon nc-single-02",
          component: StaffProfile,
          showOnSidebar: false,
          permissionLevel: 1,
        },
        {
          path: "/staffs/edit/:id",
          name: "Staff Edit",
          icon: "nc-icon nc-single-02",
          component: StaffCreateForm,
          showOnSidebar: false,
          permissionLevel: 1,
        },
        {
          path: "/staffs/:id",
          name: "Staff Dashboard",
          icon: "nc-icon nc-chart-pie-35",
          component: Dashboard,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: ["/staffs/", "/:orgType/:orgId/staffs"],
          name: "Staff Profile",
          icon: "nc-icon nc-chart-pie-35",
          component: DashboardStaffList,
          showOnSidebar: false,
          permissionLevel: 1,
        },
      ],
    },
    {
      name: "Holidays",
      icon: "nc-icon nc-air-baloon",
      showOnSidebar: true,
      permissionLevel: 0,
      component: CreateHolidays,
      subLayouts: [
        {
          path: "/holidays/create",
          name: "Create Holidays",
          icon: "nc-icon nc-air-baloon",
          component: CreateHolidays,
          showOnSidebar: true,
          permissionLevel: 3,
        },
        {
          path: "/holidays/view",
          name: "View Holidays",
          icon: "nc-icon nc-chart-pie-35",
          component: ViewHolidays,
          showOnSidebar: true,
          permissionLevel: 0,
        },
      ],
    },
    {
      name: "Organization",
      icon: "nc-icon nc-bank",
      showOnSidebar: true,
      permissionLevel: 3,
      subLayouts: [
        {
          path: "/branches/",
          name: "Branches",
          icon: "nc-icon nc-bank",
          component: BranchesList,
          showOnSidebar: false,
          permissionLevel: 1,
        },
        {
          path: "/branches/:id",
          name: "Branch Dashboard",
          icon: "nc-icon nc-bank",
          component: Dashboard,
          showOnSidebar: false,
          permissionLevel: 1,
        },
        {
          path: [
            "/:orgType/:orgId/monthly_pay",
            "/monthly_pay",
          ],
          name: "Org Dashboard Monthly Pay list",
          icon: "nc-icon nc-bullet-list-67",
          component: DashboardMonthlypayList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/branches/:id/edit",
          name: "Org Dashboard Edit",
          icon: "nc-icon nc-chart-pie-35",
          component: CreateOrgLevel,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/create-org/",
          name: "Create Org",
          icon: "nc-icon nc-bank",
          component: CreateOrgLevel,
          showOnSidebar: true,
          permissionLevel: 3,
        },
        {
          path: ["/seizedassets/", "/:orgType/:orgId/seizedassets/"],
          name: "Seized Assets",
          icon: "nc-icon nc-bullet-list-67",
          component: SeizedAssetsList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: ["/org_expenses/", "/:orgType/:orgId/org_expenses/"],
          name: "Org Expense list",
          icon: "nc-icon nc-bullet-list-67",
          component: OrgExpenseList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: ["/specialincome/", "/:orgType/:orgId/specialincome/"],
          name: "Special income list",
          icon: "nc-icon nc-bullet-list-67",
          component: SpecialIncomeList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/seizedassets/:id",
          name: "Seized Assets",
          icon: "nc-icon nc-bullet-list-67",
          component: SeizedAssetDetail,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: ["/procurements/", "/:orgType/:orgId/procurements/"],
          name: "Procurements",
          icon: "nc-icon nc-air-baloon",
          component: ProcurementsList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: ["/reports/", "/:orgType/:orgId/reports/"],
          name: "Reports",
          icon: "nc-icon nc-air-baloon",
          component: ReportsList,
          showOnSidebar: false,
          permissionLevel: 0,
        }
      ],
    },
  ];

  return {
    routes: dashboardRoutes,
  };
};

export { useRoutes };
