import { DatePicker, Input } from "antd";
import { useFilterDateDropdown, useListTitle } from "../hooks";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteRefundToExternalInvestment,
  addRetrieveRefundToExternalInvestment,
} from "../store/org";
import { NumberParam, useQueryParam } from "use-query-params";
import { getSummationOfFields } from "../utils";
import { ModifiedAntTable } from "./ModifiedAntTable";
import { Col, Row } from "react-bootstrap";

const RefundToExternalInvestmentList = () => {
  const [filterDate, setFilterDate] = useState([]);
  const [triggerRefresh, setTriggerRefresh] = useState(0);
  const [summedData, setSummedData] = useState({});
  const [filterName, setFilterName] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useQueryParam("page", NumberParam);
  const { fullListName } = useListTitle("Refund to external investments");
  const {
    users: { user },
    org: { loading, refundToExternalInvestments },
  } = useSelector((state) => ({
    users: state.users,
    org: state.org,
  }));
  const dispatch = useDispatch();
  const { renderFilter } = useFilterDateDropdown({
    setFilterDate,
    menuClassName: "mt-0",
  });

  useEffect(() => {
    setSummedData(
      getSummationOfFields({
        data: filteredData,
        fieldsToSum: ["amount"],
      })
    );

    setFilteredData(() =>
      refundToExternalInvestments?.filter(
        (instance) =>
          (instance.name ?? "")
            .toLowerCase()
            .includes(filterName?.toLocaleLowerCase()) &&
          (moment(instance?.date).isBetween(
            filterDate[0],
            filterDate[1],
            "day",
            "[]"
          ) ||
            !filterDate?.length)
      )
    );
  }, [filterName, filterDate]);

  useEffect(() => {
    setSummedData(
      getSummationOfFields({
        data: filteredData,
        fieldsToSum: ["amount"],
      })
    );
  }, [filteredData, filterName, filterDate]);

  useEffect(() => {
    dispatch(addRetrieveRefundToExternalInvestment(undefined));
  }, [triggerRefresh]);

  useEffect(() => {
    setFilteredData(refundToExternalInvestments);
  }, [refundToExternalInvestments]);

  const handleDelete = async (id) => {
    if (
      window.confirm(
        "Are you sure you want to delete this withdrawal from record?."
      )
    ) {
      await dispatch(deleteRefundToExternalInvestment(id));
      setTriggerRefresh(current => current + 1);
    }
  };

  const tableColumn = [
    {
      title: "S/N",
      dataIndex: "id",
      render: (text, record, index) =>
        ((Number(page) || 1) - 1) * 50 + (index + 1),
      summary: "Summation",
    },
    {
      title: "Date",
      dataIndex: "date",
      summary: "-",
    },
    {
      title: "Amount (₦)",
      dataIndex: "amount",
      render: (text, record, index) => (
        <div>{Number(record?.amount).toLocaleString()}</div>
      ),
      summary: summedData["amount"],
    },
    {
      title: "name",
      dataIndex: "name",
      summary: "-",
    },
  ];

  if (["DR"].includes(user?.position)) {
    tableColumn.push({
      title: "",
      dataIndex: "id",
      render: (text, record) => (
        <span role="button" onClick={() => handleDelete(record?.id)}>
          <i className="fas fa-trash"></i>
        </span>
      ),
      summary: "-",
    });
  }

  return (
    <div>
      <h3 className="page-title mb-4">{fullListName}</h3>
      <Input.Group compact className="mb-3 filter-panel">
        <Input
          placeholder="Filter by name"
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
          allowClear
        />
      </Input.Group>

      <Row>
        <Col className="d-flex">
          {renderFilter()}
          <DatePicker.RangePicker
            style={{ width: "100%" }}
            onChange={(val) => setFilterDate(val ?? [])}
            className="mb-3"
            value={filterDate}
          />
        </Col>
      </Row>

      <ModifiedAntTable
        loading={loading}
        scroll={{ x: "max-content" }}
        columns={tableColumn}
        dataSource={filteredData}
        showSummary={true}
        bordered
        pagination={{
          defaultCurrent: page,
          current: page,
          defaultPageSize: 50,
          hideOnSinglePage: true,
          onChange: (current) => setPage(current),
        }}
      />
    </div>
  );
};

export { RefundToExternalInvestmentList };
