import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { setUserDashboard } from "../store/users";
import { setOrgDashboardAction, getOrgTableData } from "../store/org";
import { showAlertAction } from "../store/alert";
import moment from "moment";
import { POSITIONS_WITH_BRANCHES, getTotal, positionToOrgMapping } from "../utils";

const useDashboard = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    users: { user, userDashboardData, metaData, loadingDashboard },
    org: { loadingOrgDashboard, orgDashboardData, metaData: orgMetaData },
  } = useSelector((state) => ({
    users: state.users,
    org: state.org,
  }));
  const [isAllOrgData, setIsAllOrgData] = useState(pathname === "/");
  const [isStaffDashboard, setIsStaffDashboard] = useState(
    pathname.includes("staffs") || user?.position === "BDO"
  );
  const [isOrgDashboard, setIsOrgDashboard] = useState(
    pathname.includes("branch") ||
      (POSITIONS_WITH_BRANCHES.includes(user.position) && !isStaffDashboard)
  );
  const [tableData, setTableData] = useState([]);
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [tableMeta, _] = useState({
    startDate: moment().subtract(4, "days"),
    endDate: moment(),
  });
  const isPersonalOrg =
    POSITIONS_WITH_BRANCHES.includes(user.position) && !isStaffDashboard;
  const orgId =
    user?.position === "BDO" ? user?.id : isPersonalOrg ? user.branch.id : id;

  const userOrgField = positionToOrgMapping[user.position];
  const cardData = !isOrgDashboard
    ? userDashboardData?.[orgId]?.cardsData
    : orgDashboardData?.[orgId]?.cardsData;

  useEffect(() => {
    if ((!isOrgDashboard || isAllOrgData) && !isPersonalOrg) {
      dispatch(
        setUserDashboard({
          userId: orgId,
          isStaffDashboard,
        })
      );
      return;
    }
    // We are on "/branches/<id>"
    dispatch(setOrgDashboardAction({ orgId, type: "branches" })).catch(
      (error) => {
        dispatch(showAlertAction(error));
      }
    );
  }, [isAllOrgData, isStaffDashboard, isOrgDashboard, id]);

  useEffect(() => {
    setIsStaffDashboard(
      pathname.includes("staffs") || user?.position === "BDO"
    );
    setIsAllOrgData(pathname === "/");
    setIsOrgDashboard(pathname.includes("branch") || isPersonalOrg);
    handleGetTableData();
  }, [pathname]);

  const handleGetTableData = async (dateRange) => {
    setLoadingTableData(true);
    isAllOrgData
    // ORG TABLE
    dispatch(
      getOrgTableData({
        org_field_id: isAllOrgData ? undefined : isOrgDashboard ? "branch_id" : "handler_id",
        org_id: orgId,
        start_date:
          dateRange?.[0]?.format("YYYY/MM/DD") ||
          tableMeta.startDate.format("YYYY/MM/DD"),
        end_date:
          dateRange?.[1]?.format("YYYY/MM/DD") ||
          tableMeta.endDate.format("YYYY/MM/DD"),
      })
    ).then((result) => {
      setTableData(result);
      setLoadingTableData(false);
    });
  };

  const getTodayTotalCollection = () => {
    const dataInst = tableData?.find((inst) =>
      moment(inst.date).isSame(new Date(), "day")
    );

    if (dataInst) {
      return getTotal(dataInst);
    }
    return "-";
  };

  return {
    userOrgField,
    metaData: isOrgDashboard ? orgMetaData?.[orgId] : metaData?.[orgId],
    accumulations: isOrgDashboard
      ? orgDashboardData?.[orgId]?.accumulations
      : userDashboardData?.[orgId]?.accumulations,
    cardsData: cardData,
    onDateRangeChange: handleGetTableData,
    tableData,
    position: user.position,
    loadingTableData,
    user,
    todayCollection: getTodayTotalCollection(),
    loadingDashboard: loadingDashboard || loadingOrgDashboard,
    user,
    isOrgDashboard,
    isStaffDashboard,
    orgType: isOrgDashboard ? "branch" : undefined,
    isAllOrgData,
  };
};

export { useDashboard };
