import { Form, Input } from "antd";
import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addRetrieveExternalInvestment } from "../store/org";

const ExternaInvestmentForm = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
1
  const handleSubmit = async (values) => {
    setLoading(true);
    await dispatch(addRetrieveExternalInvestment(values, "POST"));
    form.resetFields();
    setLoading(false);
  };


  return (
    <Container fluid>
      <h3 className="page-title mb-4">Add External Investment</h3>
      <Form form={form} onFinish={handleSubmit}>
        <Row>
          <Col className="" md="6">
            <Form.Item name="name" label="Name">
              <Input
                placeholder="Enter name"
                type="text"
                className="form-control"
              />
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              name="amount"
              label="Amount"
              rules={[
                {
                  required: true,
                  message: "Please provide amount",
                },
              ]}
            >
              <Input
                placeholder="Amount"
                type="number"
                className="form-control"
              />
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              name="date"
              label="Date"
              rules={[
                {
                  required: true,
                  message: "Please provide date",
                },
              ]}
            >
              <Input placeholder="Date" type="date" className="form-control" />
            </Form.Item>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <Button
              variant="primary"
              className="px-5"
              disabled={loading}
              type="submit"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export { ExternaInvestmentForm };
